@import "../../../styles/responsive.scss";

.addIbanBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  border: 3px solid var(--color-primary-200);
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  transition: all 0.1s;
  height: 100%;

  &:hover {
    border-color: var(--color-secondary-300);
  }
}

@include mobile {
  .addIbanBtn {
    width: 100%;
  }
}