.content {
  max-width: 764px;
  margin-top: 10px;
}

.tooltip_subheader {
  display: flex !important;
  border-bottom: 1px solid var(--color-primary-200);
  padding: 15px 10px;
  justify-content: space-between;
}

.filterUnread {

  label {
    font-size: var(--fs-13);
  }
}

.notifSubtitle {
  font-size: var(--fs-12);
  color: var(--color-primary-500);
  text-transform: uppercase;
}

.markAllAsRead {
  cursor: pointer;
  font-size: var(--fs-12);
  color: var(--color-primary-500);

  &:hover {
    text-decoration: underline;
  }
}

.tooltipWrapper {
  display: flex !important;
  flex-direction: column;
  gap: 10px;
  max-height: calc(100vh - 240px - 50px);
  overflow: auto;
  padding: 0 5px 5px;

  @supports selector(::-webkit-scrollbar) {
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--color-primary-300);
      border-radius: 45px;
      border: 3px solid transparent;
    }
  }
  @supports not selector(::-webkit-scrollbar) {
    scrollbar-width: thin;
    scrollbar-color: var(--color-primary-300) transparent;
  }
}

.noNotif {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: var(--fs-14);
  color: var(--color-primary-500);
}