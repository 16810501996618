.notFound {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4rem;
    height: 100%;
    justify-content: center;
    text-align: center;
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
    > * {
        flex: 1;
    }
    h1 {
        font-size: 6.5rem;
        line-height: initial;
        margin-bottom: 1.5rem;
    }

    h2 {
        font-size: 2.2rem;
        line-height: initial;
        margin-bottom: 1rem;
    }
}

@media (max-width: 1300px) {
    .notFound {
        width: 800px;
    }
}

.cover {
    position: relative;
}
.cover .decoration {
    position: absolute;
}

.topDeco {
    width: 2.5rem;
    right: -15px;
    top: 20px;
    color: var(--color-secondary);
}

.bottomDeco {
    fill: var(--color-tertiary);
    width: 8rem;
    bottom: 0;
    left: 10px;
}

.img {
    width: 100%;
}

.content {
    text-align: left;
    width: 30%;
}

.title {
    position: relative;
}

.circle {
    position: absolute;
    width: 380px;
    left: -30px;
    top: -40px;
}

.button {
    flex: 1;
}