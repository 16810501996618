@import '../../styles/responsive.scss';

.content {
  margin-top: 20px;
}

.heading {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.overview {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.overviewCard {
  background-color: var(--color-primary-100);
  border: 1px solid var(--color-primary-100);
  padding: 30px 20px;
  border-radius: 10px;
  width: 43%;

  &.error {
    background-color: var(--color-accent-red-300);
    border-color: var(--color-accent-red-500);
  }
  &.success {
    background-color: var(--color-accent-green-300);
    border-color: var(--color-accent-green-300);
  }
  &.secondary {
    background-color: var(--color-secondary-200);
    border-color: var(--color-secondary-200);
  }
}

.disabledOverviewCard {
  opacity: 0.6;
}

.overviewCardContent {
  display: flex;
  align-items: center;
  gap: 25px;

  ul {
    padding-left: 15px;

    li {
      &::marker {
        color: var(--color-secondary);
      }

      & + li, & span + ul {
        margin-top: 5px;
      }
    }
  }
}

.discriminatingID {
  color: var(--color-primary-500);
}

.paymentInfoAmount {
  color: var(--color-secondary);
}

.orgChart {
  max-width: 1000px;
  margin: auto;
  margin-top: 15px;
}

.orgChartLabel {
  text-align: center !important;
  display: inline-flex !important;
  align-items: center !important;
  flex-direction: column !important;
  justify-content: center !important;
  font-size: var(--fs-13) !important;
  width: 100% !important;
  max-width: 240px !important;
  min-width: 240px !important;
  padding: 15px !important;
  height: 160px !important;
}

.orgChartSource {
  background-color: var(--color-primary-100) !important;
  border-color: var(--color-primary-600) !important;
}

.orgChartLabelTitle {
  font-size: var(--fs-18);
  font-weight: 700;
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.orgChartLabelSubtitle {
  font-size: var(--fs-12);
}

.orgChartLabelDetails {
  margin: 10px 0;
  line-height: 1.33;
}

.orgChartProperty {
  border-color: var(--color-secondary) !important;

  .orgChartLabelTitle {
    font-size: var(--fs-14);
  }
}

.orgChartToValidate {
  background-color: var(--color-secondary-200) !important;
  border-color: var(--color-secondary) !important;
  height: auto !important;
  min-height: 160px !important;
}

.orgChartSuccess {
  border-color: var(--color-accent-green) !important;
  height: auto !important;
  min-height: 160px !important;
}

.orgChartReady {
  background-color: var(--color-tertiary) !important;
  border-color: var(--color-tertiary) !important;
  height: auto !important;
  min-height: 160px !important;
}

.orgChartError {
  background-color: var(--color-accent-red-300) !important;
  border-color: var(--color-accent-red) !important;
  height: auto !important;
  min-height: 160px !important;
}

.validateBtn {
  display: block;
  margin: 30px auto;
}

.orgChartPhantomLabel {
  width: 0px !important;
  height: 160px !important;
  padding: 0px !important;
  border: none !important;
  border-right: 1px solid var(--color-primary-300) !important;
  transform: scaleY(1.05) !important;
  transform-origin: top !important;
  margin-bottom: -4px !important;
}

.paymentActionsBtnContainer {
  display: flex;
  gap: 20px;
  align-items: center;
}

@include laptop-s {
  .overviewCard {
    width: 50%;
  }
}

@include mobile {
  .overviewCard {
    width: 100%;
  }

  .overview {
    flex-direction: column;
    align-items: center;
  }

  .arrowIcon {
    transform: rotate(90deg);
  }
}