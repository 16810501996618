.messageFeed {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .messageFeedHeader {
        //
    }

    .messageFeedMessages {
        flex: 1;
        display: flex;
        padding-bottom: 1rem;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: auto;
        scrollbar-color: var(--color-primary-300) transparent;
        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background-color: var(--color-primary-300);
            border-radius: 45px;
            border: 2px solid var(--color-white);
        }
    }

    .messageFeedInputWrapper {
        border-top: 1px solid var(--color-primary-100);
        padding: 0px 41px 0 20px;
    }
}

.date {
    color: var(--color-primary-500);
}

.recipientHeader {
    color: var(--color-primary-600);
}

.recipient {
    margin-top: 10px;
    display: flex;
    gap: .4rem;
}

.separator {
    border-top-color: transparent;
    border-bottom-color: var(--color-primary-100);
    border-left-color: transparent;
    border-right-color: transparent;
}

.loader {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0.6;
    z-index: 1;
    background: var(--color-white);
    display: flex;
    justify-content: center;
    align-items: center;
}