@import "../../styles/responsive.scss";

.maintenance {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4rem;
    min-height: calc(100vh - 76px - 85px);
    justify-content: center;
    text-align: center;
    width: 1000px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    > * {
        flex: 1;
    }

    h1 {
        font-size: 5.5vw;
        line-height: 1;
    }

    h2 {
        font-size: 2.2rem;
        line-height: initial;
        margin-bottom: 1rem;
    }
}

.title {
    margin-bottom: 1.5rem;
}

.footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background-color: var(--color-white);
    z-index: 1;
    padding: 20px;
    border-radius: 0 40px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    -webkit-box-shadow: 0px 5px 15px 5px var(--color-primary-200); 
    box-shadow: 0px 5px 15px 5px var(--color-primary-200);
}

.socialNetworks {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media (max-width: 1300px) {
    .maintenance {
        width: 800px;
    }
}

.cover {
    position: relative;
}
.cover .decoration {
    position: absolute;
}

.topDeco {
    width: 2.5rem;
    right: -15px;
    top: 20px;
    color: var(--color-secondary);
}

.bottomDeco {
    fill: var(--color-tertiary);
    width: 8rem;
    bottom: 0;
    left: 10px;
}

.img {
    width: 100%;
}

.content {
    text-align: left;
    width: 30%;
}

.title {
    position: relative;
}

.button {
    flex: 1;
}

.underline {
    max-width: 100%;
}

@include mobile {
    .footer {
      justify-content: center;
      border-radius: 40px 40px 0 0;
    }

    .maintenance {
        flex-direction: column-reverse;
        padding: 7.5vw;

        h1 {
            font-size: 3rem;
        }
    }

    .cover {
        width: 80%;
        flex: none;
    }

    .content {
        width: 100%;
        flex: none;
    }
}