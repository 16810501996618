.forgotPassword {
  margin-top: 1rem;
  text-align: right;
  font-size: 12.8px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 5px;
}

.forgotPasswordTitle {
  font-weight: 700;
  margin-bottom: 1.5rem;
}