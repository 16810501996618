.addForm {
  min-width: 350px;
  text-align: left;
}

.submitBtn {
  margin: 1.5rem 0;

  button {
      margin: auto;
  }
}

.errorModalSubtitle {
  max-width: 400px;
}