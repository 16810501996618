@import '../../../styles/responsive.scss';

.banner {
  position: fixed;
  z-index: 8;
  background-color: var(--color-secondary-100);
  color: var(--color-secondary);
  border-top-left-radius: 40px;
  width: calc(100vw - 206px);
  padding: 20px;
  box-shadow: 0 -4px 10px 4px rgba(48, 48, 48, 0.33);
}

.container {
  position: relative;
}

.title {
  font-size: 1.1rem;

  & > * {
    vertical-align: middle;
  }
}

.content {
  display: flex;
  align-items: center;
  column-gap: 10px;
  row-gap: 2px;
  flex-wrap: wrap;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

@include tablet {
  .banner {
    width: 100%;
    border-top-left-radius: 0px;
  }
}