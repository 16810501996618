.maskedField {
  letter-spacing: 1px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
}

.eye {
  cursor: pointer;
}

.visible {
  letter-spacing: normal;
}