.signer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.form {
  width: 100%;
  max-width: 500px;
  margin-bottom: 50px;
}

.title {
  font-size: var(--fs-24);
  font-weight: 700;
}