@import "../../../styles/responsive.scss";

.content {
    display: flex;
    gap: 20px;
    align-items: flex-start;
    margin-top: 25px;
}

.section {
    background-color: var(--color-white);
    -webkit-box-shadow: 0px 0px 12px 2px var(--color-primary-200); 
    box-shadow: 0px 0px 12px 2px var(--color-primary-200);
    border-radius: 10px;
    padding: 20px;
}

.aside {
    width: 675px;
    min-width: 675px;
    position: sticky;
    top: 0px;
    max-height: calc(100vh - 136px);
    overflow-y: auto;
    overflow-x: hidden;

    @supports selector(::-webkit-scrollbar) {
        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background-color: var(--color-primary-200);
            border-radius: 45px;
            border: 3px solid var(--color-white);
    }
    }
    @supports not selector(::-webkit-scrollbar) {
        scrollbar-width: thin;
        scrollbar-color: var(--color-primary-200) transparent;
    }
}

.gauges {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.rightCol {
    width: 100%;
    max-width: 984px;
}

@include laptop {
    .aside {
        width: 625px;
        min-width: 625px;
    }

    .section {
        padding: 15px;
        -webkit-box-shadow: 0px 0px 8px 2px var(--color-primary-200); 
        box-shadow: 0px 0px 9px 2px var(--color-primary-200);
    }

    .content {
        gap: 15px;
    }
}

@include laptop-xs {
    .aside {
        width: 100%;
        min-width: 100%;
        position: static;
        max-height: none;
    }

    .content {
        flex-direction: column;
    }
}

@include mobile {
    .content {
        margin: 25px -10px;
    }
}

@media screen and (max-width: 650px) {
    .gauges {
        flex-direction: column;
    }
}