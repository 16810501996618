.consumptionScore {
  width: 325px;
  max-width: 100%;
  height: 15px;
  margin: 4px 0;
  display: flex;
  align-items: center;
}

.consumptionScore__case {
  height: 100%;
  width: calc(100% / 7);
  display: flex;
  justify-content: center;
  align-items: center;

  &:first-child {
    border-radius: 10px 0 0 10px;
  }

  &:last-child {
    border-radius: 0 10px 10px 0;
  }
}