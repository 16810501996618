.wrapper {
    background: var(--color-white);
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 4px;
    .option {
        display: flex;
        align-items: center;
        height: 50px;
        padding: 13px 13px;
        border-radius: 6px;
        // transition: background 0.3s ease-in-out;
        font-size: var(--fs-16);
        color: var(--color-primary);
        cursor: pointer;

        &.hovered {
            background: var(--color-secondary);
            color: var(--color-white);
        }

        .icon {
            margin-right: 10px;
        }

        .label {

        }
    }
}