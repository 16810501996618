.hoverTag {
  position: absolute;
  background-color: var(--color-primary-600);
  color: var(--color-white);
  border-radius: 4px;
  padding: 3px 5px;
  font-size: var(--fs-12);
  text-wrap: nowrap;
}

.appearLeft {
  animation: appearLeft 0.3s ease;
}

.appearRight {
  animation: appearRight 0.3s ease;
}

.appearUp {
  animation: appearUp 0.3s ease;
}

.appearDown {
  animation: appearDown 0.3s ease;
}

@keyframes appearLeft {
  from {
    opacity: 0;
    transform: translate(10px, -50%);
  }
  to {
    opacity: 1;
    transform: translate(0px, -50%);
  }
}

@keyframes appearRight {
  from {
    opacity: 0;
    transform: translate(-10px, -50%);
  }
  to {
    opacity: 1;
    transform: translate(0px, -50%);
  }
}

@keyframes appearUp {
  from {
    opacity: 0;
    transform: translate(-50%, 10px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0px);
  }
}

@keyframes appearDown {
  from {
    opacity: 0;
    transform: translate(-50%, -10px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0px);
  }
}
