.addBtnWrapper {
    display: flex;
    justify-content: flex-start;

    .addBtn {
        
    }
}

.tableWrapper {
    margin-top: 30px;
}

.trashIcon {
    cursor: pointer;
}

.pagination {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}