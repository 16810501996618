@import "../../../src/styles/responsive.scss";

.heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header {
        display: flex;
        align-items: center;
    }
}

.tabLink {
    display: flex;

    // svg {
    //     width:
    // }
}

.tabList {
    list-style-type: none;
    display: flex;
    gap: 40px;

    .tabItem {
        position: relative;

        svg {
            position: absolute;
            bottom: -10px;
            left: 0;
        }

        &.active {
            a {
                color: var(--color-secondary);
                font-weight: 700;
            }
        }

        a {
            text-decoration: none;
            font-weight: 500;
            color: var(--color-primary);
            font-size: var(--fs-16);
        }
    }
}

.content {
    width: 100%;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

@include tablet {
    .tabList {
        margin-top: 1rem;

        .tabItem {
            a {
                // font-size: var(--fs-12);
            }
        }
    }
}

@include mobile {
    .heading {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .tabList {
        gap: 20px;
        .tabItem {
            a {
                font-size: var(--fs-14);
            }
        }
    }
}

@include mobile-s {
    .heading {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .tabList {
        gap: 20px;
    }
}

@include mobile-xs {
}
