@import "../../styles/responsive.scss";

.footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: var(--color-white);
  z-index: 1;
  padding: 20px;
  border-radius: 0 40px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.socialNetworks {
  display: flex;
  align-items: center;
  gap: 10px;
}

@include mobile {
  .footer {
    justify-content: center;
    border-radius: 40px 40px 0 0;
  }
}

$color-black: black;
$color-white: #fff;
$size: 170px; // (Fully responsive)

// Cat
.cat {
  position: relative;
  height: $size;
  width: $size * 1.13;
}

// Ears
.ear {
  position: absolute;
  top: -30%;
  height: 60%;
  width: 25%;
  background: $color-white;

  // Ear hair
	&::before,
	&::after {
    content: '';
    position: absolute;
    bottom: 24%;
    height: 10%;
    width: 5%;
    border-radius: 50%;
    background: $color-black;
  }

	&::after {
    transform-origin: 50% 100%;
  }
}

.earLeft {
  left: -7%;
  border-radius: 70% 30% 0% 0% / 100% 100% 0% 0%;
  transform: rotate(-15deg);

  &::before,
	&::after {
    right: 10%;
  }

	&::after {
    transform: rotate(-45deg);
  }
}

.earRight {
  right: -7%;
  border-radius: 30% 70% 0% 0% / 100% 100% 0% 0%;
  transform: rotate(15deg);

  &::before,
	&::after {
    left: 10%;
  }

	&::after {
    transform: rotate(45deg);
  }
}

// Face
.face {
  position: absolute;
  height: 100%;
  width: 100%;
  background: $color-black;
  border-radius: 50%;
}

// Eyes
.eye {
  position: absolute;
  top: 35%;
  height: 30%;
  width: 31%;
  background: $color-white;
  border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;

  // Eyelids
	&::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 100%;
    border-radius: 0 0 50% 50% / 0 0 40% 40%;
    background: $color-black;
    animation: blink 4s infinite ease-in;
  }

	@keyframes blink {
    0% { height: 0; }
		90% { height: 0; }
		92.5% { height: 100%; }
		95% { height: 0; }
		97.5% { height: 100%; }
		100% { height: 0; }
  }

	// Tips of the eyes
	&::before {
    content: '';
    position: absolute;
    top: 60%;
    height: 10%;
    width: 15%;
    background: $color-white;
    border-radius: 50%;
  }
}

.eyeLeft {
  left: 0;

  &::before {
    right: -5%;
  }
}

.eyeRight {
  right: 0;

  &::before {
    left: -5%;
  }
}

// Pupils
.eyePupil {
  position: absolute;
  top: 25%;
  height: 50%;
  width: 20%;
  background: $color-black;
  border-radius: 50%;
  animation: look-around 4s infinite;

  @keyframes look-around {
    0% { transform: translate(0) }
		5% { transform: translate(50%, -25%) }
		10% { transform: translate(50%, -25%) }
		15% { transform: translate(-100%, -25%) }
		20% { transform: translate(-100%, -25%) }
		25% { transform: translate(0, 0) }
		100% { transform: translate(0, 0) }
  }

	.eyeLeft & {
    right: 30%;
  }

	.eyeRight & {
    left: 30%;
  }

	// Glare on the pupil
	&::after {
    content: '';
    position: absolute;
    top: 30%;
    right: -5%;
    height: 20%;
    width: 35%;
    border-radius: 50%;
    background: $color-white;
  }
}

// Muzzle
.muzzle {
  position: absolute;
  top: 60%;
  left: 50%;
  height: 6%;
  width: 10%;
  background: var(--color-tertiary-600);
  transform: translateX(-50%);
  border-radius: 50% 50% 50% 50% / 30% 30% 70% 70%;
}
 .info {
  text-align: center;
  background-color: black;
  color: white;
  position: relative;
  top: -15vh;
}
.black {
  h1 {
    font-size: 100px;
    line-height: 125px;
  }
  margin-top: -50px;
  background-color: black;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  .subInfo {
    margin-top: 8vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    color: white;

    button {
      margin-top: 20px;
    }
  }
}

.centerButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav .list {
  display: flex;
  gap: 1rem;
  align-items: center;
}