@import "../../../styles/responsive.scss";

.modal {
    min-height: 500px;
}

.wrapper {
    width: 100%;
}

.addForm {
    min-width: 350px;
    text-align: left;
}

.submitBtn {
    margin: 1.5rem 0;

    button {
        margin: auto;
    }
}

.main {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;

    .thumbnail {
        width: 50px;
        height: 50px;
        position: relative;
    }

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        cursor: pointer;
    }
}

.errorModalSubtitle {
    max-width: 400px;
}

.documentTypeValue {
    font-size: var(--fs-13);
    color: var(--color-primary-500);
}

.documentNameValue {
    font-size: var(--fs-13);
    font-weight: 700;
    cursor: pointer;
    display: initial;

    &:hover {
        text-decoration: underline;
    }
}

@include mobile {
    .addForm {
        min-width: 100%;
    }
}