@import "../../../styles/responsive.scss";

.modal {
    min-height: 500px;
    max-width: 600px;
    width: 100vw;
    overflow-y: auto;
}

.addForm {
    min-width: 350px;
    text-align: left;
}

.submitBtn {
    margin: 1.5rem 0;

    button {
        margin: auto;
    }
}

.errorModalSubtitle {
    max-width: 400px;
}

.documentTypeValue {
    font-size: var(--fs-13);
    color: var(--color-primary-500);
}

.documentNameValue {
    font-size: var(--fs-13);
    font-weight: 700;
    cursor: pointer;
    display: initial;

    &:hover {
        text-decoration: underline;
    }
}

.splitLinesContainer {
    margin-top: 20px;
}

.splitLine {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    align-items: flex-start;
}

#datepickerMenu {
    & > div > div > div {
        z-index: 9999;
    }
}

.splitAmount {
    width: 100%;
}

.splitBtnContainer {
    display: flex;
    justify-content: flex-end;
}

.splitRemoveContainer {
    width: 25px;
    min-width: 25px;
    max-width: 25px;
    margin-top: 10px;
}

.splitRemove {
    cursor: pointer;
}

@include mobile {
    .addForm {
        min-width: 100%;
    }
}