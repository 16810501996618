.selector.tooltip {
    z-index: 1;
    background-color: var(--color-white);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
    border-radius: 20px;
    padding: 1rem;
    // opacity: 1;
    --rt-opacity: 1;
    pointer-events: auto;
    transition: none;

    & > div {
        // box-shadow: -1px -1px 2px rgb(0 0 0 / 30%);
        display: none;
    }
}