@import "../../styles/responsive.scss";

.heading {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.titleBlock {
    display: flex;
    gap: 20px;
    align-items: center;
}

.welcomeTitle {
    // max-width: 371px;
    font-size: var(--fs-18);
    font-weight: 500;
    line-height: 1.5;
}

.mask {
    position: absolute;
    bottom: 40px;
    right: 40px;
    max-width: 272px;
    height: 548px;
    max-height: 70%;
    object-fit: cover;
    mask-image: url(../../assets/icons/masks/lease-1.svg);
    z-index: 1;
}

.highlight {
    font-weight: 700;
}

.descriptions {
    white-space: pre-line;
    max-width: calc(100% - 272px - 20px);
}

.modalDocHelp {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.deco {
    position: absolute;
    bottom: 40px;
    right: 40px;
    z-index: 1;
}

.dashboardBasics {
    position: relative;
    z-index: 2;
}

.onboardingFunnelCTAs {
  display: flex;
  align-items: center;
  gap: 20px;
}

.ignoreOnboardingBtn {
  appearance: none;
  background: none;
  border: none;
  font-size: var(--fs-13);
  color: var(--color-secondary);
  text-decoration: underline;
  cursor: pointer;
  font-weight: 700;
  line-height: var(--fs-16);
}

@include tablet {
    .mask, .deco {
        display: none;
    }
}

/* Mobile */
@include mobile {
    .heading {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }

    .titleBlock {
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
    }

    .registerPaymentBtn {
        align-self: stretch;
    }

    .descriptions {
        max-width: unset;
    }
}