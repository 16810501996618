@import "../../../styles/responsive.scss";

.switchLessorTenantEnv {
  position: relative;
  margin-bottom: 30px;
  z-index: 100;
}

.switchLessorTenantEnv__current {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 15px;
  padding: 15px 20px;
  cursor: pointer;
  font-weight: 700;
  border-top: 1px solid var(--color-primary-500);
  border-bottom: 1px solid var(--color-primary-500);
}

.tooltip {
  width: 250px !important;
  padding: 7px !important;
  border-radius: 5px !important;
  background-color: var(--color-white) !important;
  color: var(--color-black) !important;
  transform: translate(10px, -10px) !important;
  z-index: 10 !important;

  ul {
    list-style: none;
  }
}

.tooltip__option {
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  &:hover {
    background-color: var(--color-primary-100);
  }
}

.tooltip__option__content {
  display: flex;
  align-items: center;
  gap: 10px;
}

@include mobile {
  .switchLessorTenantEnv__current {
    justify-content: flex-start; // or else sentry button hides arrow
  }
}