.wrapper {
    display: flex;
    height: 100%;
    width: 100%;
}

.leftContentWrapper {
    flex: 0 0 314px;
    height: calc(100% + 75px);
    padding: 20px 20px 0 0;
    border-radius: 0px 40px 0px 0px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6);
    position: relative;
    transform: translateX(-50px);

    .loadingOverlay {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 0px 40px 0px 0px;
        background-color: rgba(255, 255, 255, 0.6);
    }

    .leftContent {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: auto;
        scrollbar-color: var(--color-primary-400) transparent;
        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background-color: var(--color-primary-400);
            border-radius: 45px;
            border: 3px solid var(--color-white);
        }
    }

    .conversation {
        padding: 20px 20px 20px 40px;
        cursor: pointer;
        transition: transform 0.2s ease-in-out;

        &:hover {
            transform: translateX(3px);
        }

        &.isActive {
            &:hover {
                transform: none;
            }
            &:first-child {
                border-top-right-radius: 20px;
            }

            background-color: var(--color-primary-100);
            cursor: initial;
        }

        .conversationSubject {
            color: var(--color-secondary);

            &.new {
                &:before {
                    content: "\A";
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: var(--color-secondary);
                    display: inline-block;
                    margin-right: 5px;
                }
            }
        }

        .conversationDate {
            color: var(--color-primary-500);
        }
    }
}

.rightContent {
    height: calc(100% + 50px);
    flex: 1;
    margin-top: 25px;
    min-width: 320px;
}
