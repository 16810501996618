.messageFeedNewMessage {
    display: flex;
    align-items: flex-end;
    gap:  10px; 
    padding: 10px 0 35px 0;

    .leftIcon {
        width: 26px;
        height: 26px;

        margin-bottom: .5rem;
    }

    .rightIcon {
        width: 44px;
        height: 44px;
    }

    .messageFeedInput {
        flex: 1;
    }

    .messageFeedTextArea {
        max-height: 190px;
    }
}

.messageSenderAttachments {
    display: flex;
    gap: 5px;
    margin-bottom: .4rem;
}

.inputWrapper {
    display: flex;
    position: relative;

    &.loading {
        opacity: 0.5;
        pointer-events: none;
    }

    &.disabled {
        // pointer-events: none;
        cursor: not-allowed;
        opacity: .5;
    }

    .rightIcon {
        position: absolute;
        right: 20px;
        top: 13px;
    }

    label {
        position: absolute;
        left: 19px;
        top: 15%;
        transform: translateY(-15%);
        color: var(--color-primary-500);
        cursor: text;
        transition: top 0.3s ease-in-out, transform 0.3s ease-in-out, font-size 0.3s ease-in-out;

        span {
            color: var(--color-secondary);
        }
    }

    padding: .7rem 23px 5px 28px;
    border: 2px solid var(--color-primary-200);
    border-radius: 20px;
    min-height: 44px;
    max-height: 190px;

    textarea {
        width: 100%;
        background-color: transparent;
        color: var(--color-black-700);
        padding-right: 10px;
        border: none;
        outline: none;
        transition: padding-top 0.3s ease-in-out;
        appearance: none;
        font-family: "Gilroy", sans-serif;

        &[disabled] {
            border: 2px solid var(--color-primary-200);
        }

        &.error {
            border: 2px solid var(--color-accent-red-500);
        }

        &.valid {
            border: 2px solid var(--color-secondary);
        }

        &:focus,
        &:hover,
        &:active {
            outline: none;
        }

        &:focus,
        &.hasValue,
        &:not(:placeholder-shown) {
            & ~ label {
                top: -7px;
                left: 25px;
                background-color: var(--color-white);
                padding: 0 5px;
                transform: translateY(0);
                font-size: var(--fs-13);
                font-weight: 500;
                color: var(--color-primary-500);
            }
        }

        &::placeholder {
            opacity: 0;
        }

        &[type="password"] {
            padding-right: 60px;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            transition: background-color 5000s ease-in-out 0s;
        }

        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: auto;
        scrollbar-color: var(--color-secondary) transparent;
        &::-webkit-scrollbar {
            width: 6px;
        }
        &::-webkit-scrollbar-track {
            background: #D9D9D9;
            width: 6px;
            border-radius: 45px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: var(--color-secondary);
            border-radius: 45px;
            // border: 2px solid var(--color-white);
        }
    }

    label,
    textarea {
        font-size: var(--fs-16);
        font-weight: 500;
        left: 30px;
    }

    /* Chrome, Safari, Edge, Opera */
    textarea::-webkit-outer-spin-button,
    textarea::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    textarea[type="number"] {
        -moz-appearance: textfield;
    }
}

.context {
    margin: 0.3rem 0 0 1.3rem;
    font-size: var(--fs-12);
    line-height: var(--fs-14);
}

.fieldError {
    color: var(--color-accent-red-500);
}

.fieldInfo {
    color: var(--color-primary-500);
}

.viewPass {
    position: absolute;
    right: 20px;
    top: 15%;
    height: var(--fs-24);
    transform: translateY(-15%);
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
}
