@import "../../../../styles/responsive.scss";

.list {
  padding: 0 10px;
}

.listContainer {
  margin-top: 20px;
}

.link {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--color-secondary);
  font-weight: 700;
  line-height: var(--fs-16);
  cursor: pointer;

  small {
    color: var(--color-primary-500);
    font-weight: 300;
    font-size: var(--fs-12);
  }
}

.listItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  gap: 5px;

  &:not(:last-child) {
    border-bottom: 1px solid #e0e0e0;
  }
}

.noResults {
  color: var(--color-primary-300);
  text-align: center;
}

@include laptop {
  .link, .noResults {
    font-size: var(--fs-14);
  }

  .listContainer {
    margin-top: 15px;
  }
}

@media screen and (max-width: 500px) {
  .list {
    padding: 0;
  }
}