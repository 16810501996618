.form {
  width: 420px;
  max-width: 100%;
}

.title {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}