.imageUpload__img__container {
  background-color: #fff;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  height: 150px;
  width: 150px;
  -webkit-box-shadow: 5px 5px 12px 2px var(--color-primary-200); 
  box-shadow: 5px 5px 12px 2px var(--color-primary-200);
  position: relative;

  &:hover {
    .imageUpload__img {
      opacity: 0.25;
    }

    .pictoContainer {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.imageUpload__img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  transition: all .2s;
}

.pictoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all .2s;
  opacity: 0;
  transform: translateY(10px);
}