.forgotPasswordTitle {
    font-weight: 700;
    margin-bottom: 1.5rem;
}

.noAccount {
    margin-top: 1rem;
    text-align: center;
    font-size: 12.8px;

    a {
        margin-left: .45rem;
    }
}

.userAction {
    margin-top: 2rem;
}

.submit {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 30px;
}

.cancel {
    font-size: 12.8px;
}

.wrapper {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
}

.form {
    max-width: 370px;
}