@font-face {
    font-family: "Gilroy";
    src: url("../assets/fonts/Gilroy-Regular.ttf");
    font-style: normal;
    font-weight: 300;
    font-display: swap;
}
@font-face {
    font-family: "Gilroy";
    src: url("../assets/fonts/Gilroy-Medium.ttf");
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}
@font-face {
    font-family: "Gilroy";
    src: url("../assets/fonts/Gilroy-Bold.ttf");
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}

$weights: ("300", "500", "700");

:root {
    --font-primary: "Gilroy", sans-serif;
}
