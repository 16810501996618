.filtersSelected {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.filter {
    display: inline-flex;
    font-family: var(--font-primary);
    align-items: center;
    gap: 2px;
    color: var(--color-secondary);
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    font-weight: 700;
    &:hover, &:focus {
        text-decoration: underline;
    }
}