@import "../../../styles/responsive.scss";

.footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: var(--color-white);
  z-index: 1;
  padding: 20px;
  border-radius: 0 40px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.socialNetworks {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footerLogo {
  margin-right: 124px;
}

@include mobile {
  .footer {
    justify-content: center;
    border-radius: 40px 40px 0 0;
  }
}