.form {
  text-align: left;
}

.selectInput, .dateInput {
  min-width: 300px;
}

.submit {
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: center;
}

.cancel {
  font-size: 12.8px;
  background-color: transparent;
  border: none;
}