.modal {
  min-height: 300px;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.modalActions {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.mediumInput {
  width: 350px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#datepickerMenu {
  & > div > div > div {
    z-index: 9999;
  }
}

.stepper {
  width: 100%;
}