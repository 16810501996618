.tableName {
  display: flex;
  align-items: center;
  gap: 5px;
}

.seeMore {
  display: flex;
  align-items: center;

  svg {
      cursor: pointer
  }
}

.heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.maskedField {
  width: 300px;
}

.modalContent {
  width: 370px;
  margin-top: -10px;

  div:has(> button[type="submit"]) {
    justify-content: center;
  }
}

.name {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .lastName {
        font-weight: 700;
    }
}

.externalLink {
    margin-left: .3rem;
}