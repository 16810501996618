.content {
  margin: 3rem 0;
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 2rem 0 0 0;
}

.externalLink {
  margin-left: .3rem;
}