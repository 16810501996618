@import '../../styles/responsive.scss';

.content {
    margin: 3rem 0;
}

.tag {
    white-space: nowrap;
}

.pagination {
    display: flex;
    justify-content: center;
    margin: 2rem 0 0 0;
}

.price {
    color: var(--color-secondary);
}

.nameAddress {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.name {
    display: flex;
    color: var(--color-secondary);
    font-weight: 700;
    text-decoration: underline;
}

.externalLink {
    margin-left: .3rem;
}

.seeMore {
    display: flex;
    align-items: center;

    svg {
        cursor: pointer
    }
}

.headerBtn {
    text-wrap: balance;
}

@include mobile {
    .actions {
        flex-direction: column-reverse;
        gap: 20px;
    }

    .search {
        width: 100%;
        min-width: initial;
    }
}

.searchBar {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.searchOptionsCount {
    margin-top: 1rem;
    margin-left: 1rem;
    font-size: var(--fs-13);
    font-weight: 700;
}

.filter {
    display: flex;
    align-items: center;
    gap: 2px;
    font-family: var(--font-primary);
    color: var(--color-secondary);
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    font-weight: 700;
    &:hover, &:focus {
        text-decoration: underline;
    }
}

