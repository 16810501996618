.externalLink {
  margin-left: .3rem;
}

.seeMore {
  display: flex;
  align-items: center;

  svg {
      cursor: pointer
  }
}