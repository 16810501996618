.forgotPasswordTitle {
    font-weight: 700;
    margin-bottom: 1.5rem;
}

.noAccount {
    margin-top: 1rem;
    text-align: center;
    font-size: 12.8px;

    a {
        margin-left: .45rem;
    }
}

.userAction {
    margin-top: 2rem;
}

.submit {
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 30px;
}

.cancel {
    font-size: 12.8px;
    background-color: transparent;
    border: none;
}

.wrapper {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
}

.inputGroup {
    width: 420px;
    max-width: 100%;
}

.requiredDocumentsPhysical * {
    font-size: var(--fs-16) !important;
    max-width: 1000px;
}

.form {
    width: 100%;
}