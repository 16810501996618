.companyDocs {
    width: 1600px;
    max-width: 100%;
}

.modal {
    min-height: 500px;
}

.addForm {
    min-width: 350px;
    text-align: left;
}

.submitBtn {
    margin: 1.5rem 0;

    button {
        margin: auto;
    }
}

.errorModalSubtitle {
    max-width: 400px;
}

.contextMessageWrap {
    margin: 2rem 0;
    display: flex;
    align-items: flex-start;
    gap: 20px;
    height: 310px;

    .contextMessage {
        height: 100%;
        max-width: 300px;
        margin: 0;
    }
}

.contextMessageList {
    font-style: italic;
    margin: 1rem 0 0 0;
    padding: 0;
    list-style: none;
}

.missingList {
    margin-left: 20px;
    line-height: 1.5;
}

.missingBlock {
    display: flex;
    justify-content: center;
    max-width: 500px;

    & > div {
        width: 100%;
    }
}

.seeDocuments {
    display: flex;
    align-items: center;
    gap: 5px;
    text-decoration: underline;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;

    &:hover {
        transform: translateY(-2px);
    }
}

.acceptedDocuments {
    text-align: left;

    & > ul {
        margin-left: 1em;
    }
}

.contextMessageWrap {
    margin: 2rem 0;
    display: flex;
    align-items: flex-start;
    gap: 20px;
    height: 310px;

    .contextMessage {
        height: 100%;
        max-width: 300px;
        margin: 0;
    }
}

.contextMessageList {
    font-style: italic;
    margin: 1rem 0 0 0;
    padding: 0;
    list-style: none;
}

.missingBlock {
    display: flex;
    justify-content: center;
    max-width: 500px;

    & > div {
        width: 100%;
    }
}

.seeDocuments {
    display: flex;
    align-items: center;
    gap: 5px;
    text-decoration: underline;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;

    &:hover {
        transform: translateY(-2px);
    }
}