@import "../../../styles/responsive.scss";

$modalWidth: 320px;

.overlay {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: var(--overlay-black);
  transition: all .3s;
  z-index: 1;
}

.overlayOpen {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.modal {
  position: fixed;
  visibility: hidden;
  overflow: hidden;
  transform: translateX(100%);
  bottom: 0;
  left: calc(100vw - $modalWidth);
  height: calc(100vh - 76px);
  width: $modalWidth;
  display: flex;
  flex-direction: column;
  background: var(--color-white);
  border-top-left-radius: 40px;
  transition: all .3s;
  z-index: 11;

  @include tablet {
    top: 76px;
  }
  @include mobile {
    left: 0;
    width: 100vw;
    border-top-left-radius: 0;
  }
}

.modalOpen {
  visibility: initial;
  transform: none;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.header {
  display: flex;
  position: sticky;
  top: 0;
  background-color: white;
  padding: 20px 0;
  box-shadow: -10px 30px 20px white;
  z-index: 1;

  h2 {
    display: inline;
    position: relative;
    flex: 1;
    .trace {
      position: absolute;
      left: 0;
      bottom: -12px;
    }
  }
}

.close {
  background: transparent;
  border: none;
  cursor: pointer;
}

.close::before {
  content: "\2715";
  color: var(--idema-color-primary-dark);
  font-size: 1.45rem;
}

.content {
  padding: 0 23px;
}