@import "../../styles/responsive.scss";

.welcomeTitle {
    max-width: 371px;
}

.mask {
    position: absolute;
    bottom: 40px;
    right: 40px;
    width: 272px;
    height: 548px;
    object-fit: cover;
    mask-image: url(../../assets/icons/masks/lease-1.svg);
    z-index: 1;
}

.highlight {
    font-weight: 700;
}

@include tablet {
    .mask {
        display: none;
    }
}