@import "../../../src/styles/responsive.scss";

.heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.editDetails {
    display: flex;
    gap: 20px;
    align-items: center;
}

.tabList {
    list-style-type: none;
    display: flex;
    column-gap: 40px;
    row-gap: 25px;
    flex-wrap: wrap;

    .tabItem {
        position: relative;
        text-wrap: nowrap;

        svg {
            position: absolute;
            bottom: -10px;
            left: 0;
        }

        &.active {
            a {
                color: var(--color-secondary);
                font-weight: 700;
            }
        }

        a {
            text-decoration: none;
            font-weight: 500;
            color: var(--color-primary);
            font-size: var(--fs-16);
        }
    }
}

@include tablet {
}

@include mobile {
    .heading {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

@include mobile-s {
    .heading {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

