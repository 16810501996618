.container {
  border-top-right-radius: 20px;
  background-color: var(--color-primary-100);
  height: calc(100vh - 76px - 110px);
  width: 210px;
  padding: 20px 0;
  font-size: 12.8px;
  box-sizing: border-box;
  overflow-y: hidden;
  overflow-x: hidden;
  padding-right: 5px;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    height: 100%;

    overflow-y: auto;
    overflow-x: hidden;
  
    @supports selector(::-webkit-scrollbar) {
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--color-primary-200);
        border-radius: 45px;
      }
    }
    @supports not selector(::-webkit-scrollbar) {
      scrollbar-width: thin;
      scrollbar-color: var(--color-primary-200) transparent;
    }
    
    li {
      padding: 9px 18px;
      position: relative;

      a {
        color: var(--color-black);
        text-decoration: none;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 8px;

        &:global(.active) {
          color: var(--color-secondary);
          font-weight: 700;
        }

        &[disabled] {
          opacity: 0.25;
          cursor: default;
          pointer-events: none;
        }
      }

      &:has(:global(.active))::before {
        content: "";
        position: absolute;
        left: 0;
        top: 8px;
        width: 4px;
        height: 26px;
        border-radius: 0px 3px 3px 0px;
        background-color: var(--color-secondary);
      }
    }
  }

  .nestedRoutes {
    margin: 9px -18px 0;

    li {
      padding: 7px 18px 7px 46px;

      a.active {
        color: var(--color-secondary);
        font-weight: 700;
      }
    }
  }
}