@import "../../styles/responsive.scss";

.searchView {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.searchCol {
  border-radius: 0px 40px 0px 0px;
  background: var(--color-primary-100);
  padding: 40px;
  width: 31vw;
  max-width: 700px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  -webkit-box-shadow: 2px 0px 12px 2px rgba(0,0,0,0.15); 
  box-shadow: 2px 0px 12px 2px rgba(0,0,0,0.15);

  .noResults {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    padding: 10px 0;
  }
}

.searchInput {
  background-color: var(--color-white) !important;
  border-radius: 25px;

  em {
    font-style: normal;
    font-weight: 700;
  }
}

.filtersHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.resultsCount {
  font-size: var(--fs-13);
  font-weight: 700;
}

.filterBtn {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--font-primary);
  color: var(--color-secondary);
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  font-weight: 700;
  &:hover, &:focus {
    text-decoration: underline;
  }
}

.resultsList {
  margin: 20px -30px 0;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 354px);
  padding: 0 30px;

  @supports selector(::-webkit-scrollbar) {
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--color-primary-300);
      border-radius: 45px;
      border: 3px solid transparent;
    }
  }
  @supports not selector(::-webkit-scrollbar) {
    scrollbar-width: thin;
    scrollbar-color: var(--color-primary-300) transparent;
  }

  & > * {
    margin-bottom: 20px;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.propertyDetails {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

@include laptop {
  .searchCol {
    width: 40%;
    max-width: unset;
  }
}

@include laptop-s {
  .searchCol {
    width: 45%;
  }
}

@include laptop-xs {
  .searchCol {
    width: 50%;
  }
}

@include tablet {
  .searchCol {
    border-radius: 0px 30px 0px 0px;
    padding: 30px;
  }

  .resultsList {
    margin: 20px -25px 0;
    padding: 0 20px;
  }
}

@include mobile {
  .searchCol {
    border-radius: 30px 30px 0px 0px;
    width: 100%;
    height: 60vh;
    overflow: auto;
    bottom: 0;
    top: unset;
  }

  .isPublic .searchCol {
    bottom: 65px;
    height: calc(60vh - 65px);
  }

  .searchView {
    height: calc(100vh - 76px);
  }
}