@import "../../../styles/responsive.scss";

.photoGallery {
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(25% - 20px) calc(25% - 10px);
  grid-template-rows: 185px 185px;
  gap: 20px;
}

.photoGallery__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.photoGallery__img__container {
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  &:hover {
    .photoGallery__img {
      transform: scale(1.05);
    }
  }
}

.photoGallery__img__container__main {
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: 3;
}

.photoGallery__counter {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: var(--color-primary);
  padding: 5px 10px;
  border-radius: 20px;
  color: var(--color-white);
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: var(--fs-13);
  font-weight: 700;
}

// .photoGallery__pswp {
//   img {
//     width: auto !important;
//     height: auto !important;
//   }
// }

@include mobile {
  .photoGallery {
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    grid-template-rows: 200px;
  }

  .photoGallery__img__container__main {
    display: flex;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .photoGallery__counter {
    bottom: 10px;
    right: 10px;
  }
}