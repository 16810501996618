.modal {
    min-height: 500px;
}

.addForm {
    min-width: 350px;
    text-align: left;
}

.submitBtn {
    margin: 1.5rem 0;

    button {
        margin: auto;
    }
}

.errorModalSubtitle {
    max-width: 400px;
}

.documentTypeValue {
    font-size: var(--fs-13);
    color: var(--color-primary-500);
}

.documentNameValue {
    font-size: var(--fs-13);
    font-weight: 700;
    cursor: pointer;
    display: initial;

    &:hover {
        text-decoration: underline;
    }
}