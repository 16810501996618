@import "../../../../styles/responsive.scss";

.container {
  display: flex;
  align-items: center;
  gap: 75px;
  padding: 0 50px 0 20px;
}

.legend {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.legendSkeleton {
  width: 100%;
}

.legendItem {
  display: flex;
  align-items: center;
  gap: 15px;
  font-weight: 700;
  font-size: var(--fs-18);
}

.legendColor {
  width: 25px;
  height: 25px;
  min-width: 25px;
  border-radius: 50%;
}

.legendLabel {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
  width: 100%;
}

.legendLink {
  color: var(--color-primary);
  text-decoration: none;
  color: var(--color-primary-400);
  font-size: var(--fs-14);
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
}

@media screen and (max-width: 600px) {
  .container {
    gap: 15%;
    padding: 0 20px;
  }

  .legend {
    gap: 15px;
  }

  .legendItem {
    gap: 10px;
    font-size: var(--fs-16);
  }

  .legendColor {
    width: 20px;
    height: 20px;
    min-width: 20px;
  }

  .legendLink {
    font-size: var(--fs-13);
  }
}

@media screen and (max-width: 500px) {
  .container {
    gap: 7.5%;
    padding: 0;
  }

  .legendItem {
    gap: 10px;
    font-size: var(--fs-14);
  }

  .legendColor {
    width: 15px;
    height: 15px;
    min-width: 15px;
  }

  .legendLink {
    font-size: var(--fs-12);
  }
}

@include mobile-s {
  .legend {
    gap: 7px;
  }
  .legendLabel {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
}