.icon {
  cursor: pointer;
}

.wrapper {
  position: relative;
}

.unread {
  position: absolute;
  top: -3px;
  right: -3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid white;
  background-color: var(--color-secondary);

  @keyframes pulse {
      0% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
      }
      70% {
          transform: scale(1);
          box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
      }
      100% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
      }
  }

  animation: pulse 1s infinite;
  animation-iteration-count: 30;
}

.tooltip {
  width: 500px !important;
  padding: 7px !important;
  border-radius: 5px !important;
  background-color: var(--color-white) !important;
  color: var(--color-black) !important;
  z-index: 11 !important;
  max-height: calc(100vh - 100px) !important;
  overflow: hidden !important;
  max-width: calc(100vw - 10px) !important;
}

.tooltipHeader {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}

.notifLinkWrapper {
  padding-top: 3px;
  position: relative;
  cursor: pointer;
}

.notifLink {
  cursor: pointer;
}

.tooltip_subheader {
  display: flex !important;
  margin: 0 15px;
  border-top: 1px solid var(--color-primary-200);
  padding: 10px 0 15px;
  justify-content: space-between;
}

.notifSubtitle {
  font-size: var(--fs-12);
  color: var(--color-primary-500);
  text-transform: uppercase;
}

.markAllAsRead {
  cursor: pointer;
  font-size: var(--fs-12);
  color: var(--color-primary-500);

  &:hover {
    text-decoration: underline;
  }
}

.tooltipWrapper {
  display: flex !important;
  flex-direction: column;
  gap: 10px;
  max-height: calc(100vh - 220px);
  overflow: auto;
  padding: 0 5px 5px;

  @supports selector(::-webkit-scrollbar) {
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--color-primary-300);
      border-radius: 45px;
      border: 3px solid transparent;
    }
  }
  @supports not selector(::-webkit-scrollbar) {
    scrollbar-width: thin;
    scrollbar-color: var(--color-primary-300) transparent;
  }
}

.noNotif, .loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: var(--fs-14);
  color: var(--color-primary-500);
}

.notifSkeleton {
  padding: 5px 10px;
}