.content {
  margin: 25px 0 3rem;
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 2rem 0 0 0;
}

.externalLink {
  margin-left: .3rem;
}

.seeMore {
  display: flex;
  align-items: center;

  svg {
      cursor: pointer
  }
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
}