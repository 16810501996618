.heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

.link {
  color: var(--color-white);
  text-decoration: none;
}