.editIban {
  font-size: var(--fs-12);
  text-align: right;
  color: var(--color-primary-500);
  margin-top: 0.7rem;

  .editIbanBtn {
    cursor: pointer;
    display: inline-block;

    &:hover {
      text-decoration: underline;
    }
  }
}