.content {
  margin: 0 0 3rem;
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 2rem 0 0 0;
}

.externalLink {
  margin-left: .3rem;
}

.paymentsList {
    margin-top: 5px;
    width: 100%;
}

.noPayments {
    font-size: var(--fs-13);
    display: block;
    margin: 0 10px 15px;
    color: var(--color-primary-500);
}

.seeMore {
  display: flex;
  align-items: center;

  svg {
      cursor: pointer
  }
}