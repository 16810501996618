@import '../../styles/responsive.scss';

.inventoryEdit {
  margin-top: 109px;
  height: calc(100vh - 76px - 109px);
  overflow-y: auto;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  padding: 35px 50px 25px;
  background-color: var(--color-white);
  position: fixed;
  z-index: 2;
  top: 76px;
  left: 206px;
  border-radius: 40px 0px 0px 0px;
  width: calc(100% - 206px);
  max-width: calc(1920px - 206px);
  height: 110px;
}

.title {
  line-height: normal;
  text-wrap: nowrap;

  * {
    vertical-align: middle;
  }
}

.statusTag {
  font-weight: normal;
}

.saveCol {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}

.draftSaved {
  color: var(--color-accent-green);
  text-align: right;
}

.draftSavedText {
  vertical-align: middle;
}

.draftSavedIcon {
  vertical-align: middle;
  margin-right: 5px;
}

.menu {
  position: fixed;
  z-index: 2;
}

.content {
  margin-left: 210px;
  padding: 30px;
  min-height: 100%;
}

@include laptop {
  .heading {
    padding: 30px 30px 25px;
  }
}

@include tablet {
  .heading {
    width: 100%;
    left: 0;
    padding: 20px;
  }

  .content {
    padding-bottom: 100px;
  }
}

@include mobile {
  .inventoryEdit {
    margin-top: 145px;
    height: calc(100vh - 76px - 145px);
  }

  .heading {
    z-index: 3;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    height: 95px;
    border-top-left-radius: 0;
  }

  .title {
    font-size: var(--fs-24);
  }

  .draftSaved {
    font-size: var(--fs-14);
  }

  .draftSavedIcon {
    width: 18px;
  }

  .saveBtn {
    position: fixed;
    z-index: 3;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-color: var(--color-white);
    padding: 10px;

    button {
      width: 100%;
    }
  }

  .menu {
    height: calc(100vh - 76px - 95px);
    width: 100vw;
    border-top-right-radius: 0;
    padding-bottom: 90px;
    transform: translateX(-100%);
    transition: transform 0.3s;
    background-color: var(--color-white);

    &.menuOpen {
      transform: translateX(0);
    }
  }

  .content {
    margin-left: 0;
    padding: 20px 20px 100px;
  }
}