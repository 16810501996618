@import "../../../../styles/responsive.scss";

$largeInput: 480px;
$mediumInput: 240px;

.largeInput {
    width: $largeInput;
}

.mediumInput {
    width: $mediumInput;
}

.forgotPasswordTitle {
    font-weight: 700;
    margin-bottom: 1.5rem;
}

.noAccount {
    margin-top: 1rem;
    text-align: center;
    font-size: 12.8px;

    a {
        margin-left: .45rem;
    }
}

.linkBtn {
    margin-top: 2rem;
}

.userAction {
    margin-top: 2rem;
}

.submit {
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 30px;
}

.cancel {
    font-size: 12.8px;
}

.dateInput {
    max-width: 200px;
}

.wrapper {
    margin-top: 50px;
}

.form {
    flex: 1;
    margin-bottom: 5rem;
}

.modal {
    min-height: 500px;
    width: 500px;

    .modalSubmitBtn {
        display: inline;
        text-align: center;
    }
}

.tenants {
    margin-top: 2rem;
}

.lastRentDates {
    display: flex;
    gap: 1rem;
    width: $largeInput;
}

.tableName {
    display: flex;
    gap: .23rem;
    flex-direction: column;
    padding: 1rem 0;
}

.fileNameHeader {
    color: var(--color-primary-500);
}

.propertyName {
    color: var(--color-secondary);
    text-decoration: underline;
}

.address {
    display: flex;
    gap: .15rem;
    margin-top: .2rem;
    flex-direction: column;
    // font-size: var(--fs-13);
}

.seeMore {
    display: flex;
    align-items: center;

    svg {
        cursor: pointer
    }
}

.documentNameValue {
    cursor: pointer;
    display: initial;

    &:hover {
        text-decoration: underline;
    }
}

.documentTypeValue {
    font-size: var(--fs-13);
    color: var(--color-primary-500);
}

.documentNameValue {
    font-size: var(--fs-13);
}

.documentList {
    flex: 1;
}

.wysiwygPreview {
    width: 100%;
}

.previewSection {
    width: 100%;
}

@include mobile {
    .largeInput {
        width: 100%;
    }

    .mediumInput {
        width: 100%;
    }

    .modal {
        width: 100%;
    }
}