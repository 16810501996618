.help {
    display: flex;
    align-items: center;
    gap: 5px;

    svg {
        min-width: 15px;
    }
}

.text {
    text-wrap: balance;
}