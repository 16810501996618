@import '../../../styles/responsive.scss';

.sameLocationList {

}

.count {
  color: var(--color-secondary);
  font-size: var(--fs-14);
  font-weight: 700;
  line-height: 1.25;
  display: block;
  margin-top: 5px;
}

.list {
  margin: 20px -30px 0;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 354px);
  padding: 0 30px;

  scrollbar-width: auto;
  scrollbar-color: var(--color-primary-500) transparent;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--color-primary-300);
    border-radius: 45px;
    border: 3px solid transparent;
  }

  & > * {
    margin-bottom: 20px;
  }
}

.goBack {
  appearance: none;
  background: none;
  border: none;
  color: var(--color-secondary);
  font-weight: 700;
  font-size: var(--fs-13);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;

  &:hover span {
    text-decoration: underline;
  }
}

.backArrow {
  transform: rotate(90deg);
}

@include tablet {
  .list {
    margin: 20px -25px 0;
    padding: 0 20px;
  }
}