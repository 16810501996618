.userSection {
    text-align: center;
    padding: 0 0 1.1rem 0;
    width: 100%;

    .userTitle {
        font-weight: 700;
    }

    .userAction {
        font-size: 12.8px;
        line-height: var(--fs-20);
    }
}

.forgotPassword {
    margin-top: 1rem;
    text-align: right;
    font-size: 12.8px;
}

.forgotPasswordTitle {
    font-weight: 700;
    margin-bottom: 1.5rem;
}

.noAccount {
    margin-top: 1rem;
    text-align: center;
    font-size: 12.8px;

    a {
        margin-left: .45rem;
    }
}

.userAction {
    margin-top: 2rem;
}