.backButton {
  appearance: none;
  background: none;
  border: none;
  color: var(--color-secondary);
  font-weight: 700;
  font-size: var(--fs-13);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 10px 0 15px -5px;

  &:hover span {
    text-decoration: underline;
  }
}

.backArrow {
  transform: rotate(90deg);
}