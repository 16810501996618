@import '../../../styles//responsive.scss';

.container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.legend {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.title {
  font-size: var(--fs-13);
  color: var(--color-primary-500);
  margin-bottom: 3px;
}

.label {
  font-size: var(--fs-18);
  font-weight: 700;
}

.progressPercent {
  font-size: var(--fs-13);
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 2px;

  &.positive {
    color: var(--color-accent-green);

    .icon {
      transform: rotate(180deg);
    }
  }

  &.negative {
    color: var(--color-accent-red);
  }
}

@include laptop {
  .label {
    font-size: var(--fs-16);
  }
}