.modal {
  text-align: left;
}

.modalButtonContainer {
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: center;
}

.cancel {
  font-size: 12.8px;
  color: var(--color-secondary);
  text-decoration: underline;
  cursor: pointer;
  font-weight: 700;
  line-height: var(--fs-16);
}

#datepickerMenu {
  & > div > div > div {
    z-index: 9999;
  }
}