@import "../../../styles/responsive.scss";

.welcomeTitle {
    max-width: 371px;
}

.mask {
    position: absolute;
    bottom: 40px;
    right: 40px;
    width: 272px;
    height: 548px;
    object-fit: cover;
    mask-image: url(../../../assets/icons/masks/lease-1.svg);
    z-index: 1;
}

.content {
    margin: 3rem 0;
}

.pagination {
    display: flex;
    justify-content: center;
    margin: 2rem 0 0 0;
}

.price {
    color: var(--color-secondary);
}

.name {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .lastName {
        font-weight: 700;
    }
}

.externalLink {
    margin-left: .3rem;
}

.seeMore {
    display: flex;
    align-items: center;

    svg {
        cursor: pointer
    }
}

.actions {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.search {
    min-width: 500px;
}

.ratio {
    &.positive {
        color: var(--color-accent-green);
    }
    &.negative {
        color: var(--color-accent-red-500);
    }
}

@include tablet {
    .mask {
        display: none;
    }
}

@include mobile {
    .actions {
        flex-direction: column-reverse;
        gap: 20px;
    }

    .search {
        width: 100%;
        min-width: initial;
    }
}

.searchBar {
    display: flex;
    align-items: center;
    gap: 3rem;
}


.filters {
    flex: 1;
}

.filter {
    display: flex;
    align-items: center;
    gap: 2px;
    color: var(--color-secondary);
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    font-weight: 700;
    &:hover, &:focus {
        text-decoration: underline;
    }
}

.filterGroup {
    margin: 2rem 0;
    h3 {
        margin-bottom: .9rem;
    }
}

.filterSeparator {
    margin: 0 -23px;
    background-color: var(--color-primary-100);
    height: 1px;
    border: none;
}

.filterActions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    background-color: white;
}

.reinit {
    font-size: .8rem;
    font-weight: 700;
    color: var(--color-secondary);
    text-decoration: underline;
    cursor: pointer;
}