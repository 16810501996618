@import "../../../styles/responsive.scss";

.propertyDetails {
  background-color: var(--color-white);
  padding: 40px;
  overflow: auto;
}

.propertyDetails__container {
  max-width: 2500px;
}

.goBack {
  appearance: none;
  background: none;
  border: none;
  color: var(--color-secondary);
  font-weight: 700;
  font-size: var(--fs-13);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover span {
    text-decoration: underline;
  }
}

.backArrow {
  transform: rotate(90deg);
}

.propertyDetails__subheader {
  margin-bottom: 40px;
}

.propertyDetails__header__title {
  display: flex;
  gap: 10px;
  align-items: center;
}

.city {
  margin-top: 10px;
  display: block;
}

.mainInfos {
  margin-top: 10px;
  display: block;
  color: var(--color-primary-600);
}

.propertyDetails__body {
  margin-top: 40px;
}

.propertyDetails__body__description {
  h2 {
    margin-bottom: 5px;
  }
}

.propertyDetails__body__heatingType, .propertyDetails__body__equipments {
  margin-top: 40px;
}

.ticked {
  display: flex;
  align-items: center;
  gap: 5px;
}

.propertyDetails__body__heatingType__list, .propertyDetails__body__equipments__list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.propertyDetails__body__list__horizontal {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;

  & > * {
    width: calc(100% / 3 - 20px);
  }
}

.propertyDetails__body {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

.propertyDetails__body__main {
  width: calc(100% - 325px - 20px);
}

.propertyDetails__body__aside {
  width: 325px;
  min-width: 325px;
  background-color: var(--color-primary-100);
  padding: 40px 20px;
  border-radius: 40px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  hr {
    background-color: var(--color-primary-200);
    border: none;
    height: 1px;
  }
}

.propertyDetails__body__aside__rent, .rentDetailsItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.propertyDetails__body__aside__rent {
  font-weight: 700;
}

.totalRent {
  color: var(--color-secondary);
  font-size: var(--fs-20);
}

.includedCharges {
  font-size: var(--fs-10);
  color: var(--color-primary-500);
  text-align: right;
  display: block;
  line-height: 1.5;
}

.candidateDesc {
  font-size: var(--fs-13);
  line-height: 1.25;
  margin-top: 5px;
}

.propertyDetails__gallery {
  margin-top: 40px;
}

.propertyDetails__no_gallery {
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 18px;
}

.propertyDetails__no_gallery__icon {
  position: relative;

  .propertyDetails__no_gallery__scribble {
    position: absolute;
    bottom: -2px;
    right: -8px;
  }
}

.propertyDetails__body__diagnostics {
  margin-top: 40px;

  h3 {
    font-size: 17px;
  }
}

.propertyDetails__body__diagnostics__container {
  margin-top: 10px;
  display: flex;
  column-gap: 20%;
  row-gap: 20px;
  flex-wrap: wrap;
}

.propertyDetails__body__diagnostics__dpe, .propertyDetails__body__diagnostics__ges {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 100%;
}

.propertyDetails__body__diagnostics__desc {
  font-size: var(--fs-13);
}

.propertyDetails__body__map {
  margin-top: 40px;
}

.propertyDetails__body__map__address {
  font-size: var(--fs-13);
  margin-top: 10px;
}

.propertyDetails__body__map__map {
  margin-top: 20px;
  height: 330px;
  width: 63vw;
}

.marker {
  background-color: var(--color-primary);
  padding: 10px;
  border-radius: 50%;
  transform: translateY(50%);
}

.userCtas {
  display: flex;
  justify-content: center;
  margin-top: 1.1rem;

  .storePill {
    height: 55px;
  }
}

.titleError {
  display: flex;
  align-items: center;
  gap: 10px;
}

@include laptop-s {
  .propertyDetails__body {
    flex-direction: column-reverse;
    gap: 40px;
  }

  .propertyDetails__body__aside {
    width: 100%;
    min-width: unset;
    border-radius: 10px;
    padding: 20px;
  }

  .propertyDetails__body__main {
    width: 100%;
  }

  .propertyDetails__body__map__map {
    width: calc(100vw - 206px - 80px);
  }
}

@include tablet {
  .propertyDetails__body__map__map {
    width: calc(100vw - 80px);
  }
}

@include mobile {
  .propertyDetails__body__list__horizontal {
    flex-direction: column;

    & > * {
      width: 100%;
    }
  }

  .propertyDetails {
    padding: 30px;
  }

  .propertyDetails__body__map__map {
    width: calc(100vw - 60px);
  }
}