@import "../../../src/styles/responsive.scss";

.heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@include mobile {
    .heading {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

@include mobile-s {
    .heading {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}
