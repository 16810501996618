.tooltip__option {
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  align-items: flex-start;

  &:hover {
    background-color: var(--color-primary-100);
  }
}

.tooltip__option__content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.tooltip__option__title {
  font-weight: 700;
  font-size: var(--fs-14);
}

.notifAgo {
  font-size: var(--fs-12);
  color: var(--color-primary-500);
  font-weight: 500;
}

.tooltip__option__text {
  font-size: var(--fs-13);
  color: var(--color-primary-600);
}

.tooltip__option__markAsRead {
  position: relative;
}

.tooltip__option__markAsRead__icon {
  border: 1px solid transparent;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 5px;

  &:hover {
    background-color: var(--color-white);
  }

  &.notif_unread::after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background-color: var(--color-secondary);
    border-radius: 50%;
  }
}

.tooltip__option:hover {
  .tooltip__option__markAsRead__icon {
    border-color: var(--color-primary-200);
  }
}