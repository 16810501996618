@import "../../../styles/responsive.scss";

.stepTitle {
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 10px;

    small {
        color: var(--color-primary-500);
        font-size: var(--fs-13);
    }

    h3 {
        text-wrap: balance;
    }
}

.stepTitleText {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.documentTypeValue {
    font-size: var(--fs-13);
    color: var(--color-primary-500);
}

.documentNameValue {
    font-size: var(--fs-13);
    font-weight: 700;
    cursor: pointer;
    display: initial;
    text-decoration: underline;
    color: var(--color-secondary);
}

.deleteIcon {
    margin-left: auto;
    display: block;
    cursor: pointer;
}

.addIcon {
    display: block;
    margin-left: auto;
}

.titleH4 {
    font-weight: 700;
    font-size: var(--fs-16);
}

.infoIcon {
    cursor: pointer;
    min-width: 20px;
}

.textContent {
    ul {
        font-size: var(--fs-16);
        margin-left: 30px;
        line-height: 1.33;
    }
}

@include mobile {
    .stepTitle {
        h3 {
            font-size: var(--fs-16);
            line-height: 1.25;
        }
        .titleH4 {
            font-size: var(--fs-14);
        }
    }

}