@import "../../../styles/responsive.scss";

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 11;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalWrapper {
    border-radius: 20px;
    background-color: var(--color-white);
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    max-height: 90vh;
    overflow-y: hidden;
    display: flex;

    .modalContainer {
        // max-height: calc(90vh - 30px);
        padding: 30px;
        flex: 1;

        overflow-y: auto;
        overflow-x: hidden;

        @supports selector(::-webkit-scrollbar) {
            &::-webkit-scrollbar {
                width: 10px;
            }
            &::-webkit-scrollbar-track {
                background: transparent;
            }
            &::-webkit-scrollbar-thumb {
                background-color: var(--color-primary-300);
                border-radius: 45px;
                border: 3px solid var(--color-white);
        }
        }
        @supports not selector(::-webkit-scrollbar) {
            scrollbar-width: thin;
            scrollbar-color: var(--color-primary-300) transparent;
        }
    }
}

.small {
    max-width: 635px;
}

.regular {
    max-width: 800px;
}

.large {
    max-width: 1100px;
}

.modalContentWrap {
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 1rem;

    &.noWrap {
        padding-left: 0;
        padding-right: 0;
    }
}

.modalHeader {
    margin: 1.3rem 0 0 0;
}

.modalClose {
    background: transparent;
    border: 0;
    cursor: pointer;
}

.modalClose:before {
    content: "\2715";
    color: var(--idema-color-primary-dark);
    font-size: 1.45rem;
}

.modalContent {
    margin-top: 2rem;
    margin-bottom: 2rem;
    line-height: 26px;
    color: rgba(0, 0, 0, 0.8);

    &.noTitle {
        margin-top: 0;
    }
}

@keyframes mmfadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes mmfadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes mmslideIn {
    from {
        transform: translateY(15%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes mmslideOut {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-10%);
    }
}

.micromodalSlide {
    display: none;
}

.micromodalSlide.isOpen {
    display: block;
}

.micromodalSlide[aria-hidden="false"] .modalOverlay {
    animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodalSlide[aria-hidden="false"] .modalContainer {
    animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodalSlide[aria-hidden="true"] .modalOverlay {
    animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodalSlide[aria-hidden="true"] .modalContainer {
    animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodalSlide .modalContainer,
.micromodalSlide .modalOverlay {
    will-change: transform;
}

/* Mobile */
@include mobile {
    .modalWrapper .modalContainer {
        padding: 18px;
        height: 100vh;
        max-height: 100vh;
        border-radius: 0;
        margin: 0;
    }

    .modalContentWrap {
        padding-left: 0rem;
        padding-right: 0rem;
        margin-top: 1rem;
    }
}
