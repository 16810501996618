.content {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: space-between;
  min-height: calc(100vh - 76px - 100px - 55px - 55px);  // screen - header - padding - title - prevStep
}

.heading {
  display: flex;
  align-items: center;
  column-gap: 30px;
  row-gap: 20px;
  flex-wrap: wrap-reverse;
}

.footer {
  display: flex;
  align-items: center;
  gap: 30px;
}

.skip {
  appearance: none;
  background: none;
  border: none;
  font-size: var(--fs-13);
  color: var(--color-secondary);
  text-decoration: underline;
  cursor: pointer;
  font-weight: 700;
  line-height: var(--fs-16);
}

.hiddenBackBtn {
  visibility: hidden;
}