@import "../../../styles/responsive.scss";

.messageBlock {
    max-width: 800px;
}

.messageBlockLeft {
    display: block;
    float: left;
}

.messageBlockRight {
    display: block;
    float: right;
}

.date {
    color: var(--color-primary-500);
}

.name {
    color: var(--color-primary-600);
}

.attachmentList {
    margin-top: 20px;
    width: 370px;
    list-style-type: none;
    border-top: 1px solid var(--color-primary-100);
}

.attachmentElement {
    padding: 10px 0;
    display: flex;
    align-items: flex-start;
    gap: 10px; 
    border-bottom: 1px solid var(--color-primary-100);

    svg, p {
        cursor: pointer;
    }

    svg {
        transition: transform 0.2s ease-in-out;
        &:hover {
            transform: translateY(2px);
        }
    }
}

.documentNameValue {
    &:hover {
        text-decoration: underline;
    }
}

.separator {
    border-top-color: transparent;
    border-bottom-color: var(--color-primary-100);
    border-left-color: transparent;
    border-right-color: transparent;
}

@include mobile {
    .messageBlock {
        max-width: 100%;
    }

    .attachmentList {
        width: 100%;
    }
}