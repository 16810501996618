@import '../../styles/responsive.scss';

.postAdView {
  padding: 50px;
  height: calc(100vh - 76px - 85px);  // screen - header - footer
  display: flex;
  flex-direction: column;
  gap: 35px;
  justify-content: space-between;
  // align-items: flex-start;
  overflow-y: auto;
}

.title {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.titleIcon {
  margin-bottom: 7px;
}

.content {
  margin-top: 1.5rem;
  width: 100%;
}

.form {
  max-width: 500px;
}

.footer {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.inputCols {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.smallInputs {
  max-width: 370px;
}

.successMsg * {
  font-size: var(--fs-16) !important;
}

.successMsgContent {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.nav .list {
  display: flex;
  gap: 1rem;
  align-items: center;
}

@include mobile {
  .postAdView {
    padding: 50px 50px 100px;
    height: calc(100vh - 76px);  // screen - header
    gap: 20px;
  }
}