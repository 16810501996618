.nav {
    display: flex;
    align-items: center;

    .list {
        display: flex;
        align-items: center;
        list-style: none;

        .item {
            
        }

        .itemIcon {
            height: 24px;
            cursor: pointer;
            transition: transform 0.2s ease-in-out;
            
            &:hover {
                transform: translateY(1px);
            }
        }

        .left {
            margin-left: 2.5rem;
        }
    }
}

.messageIcon {
    position: relative;
}

.unread {
    position: absolute;
    top: -3px;
    right: -3px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid white;
    background-color: var(--color-secondary);

    @keyframes pulse {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
        }
        70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
        }
        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        }
    }

    animation: pulse 1s infinite;
    animation-iteration-count: 30;
}