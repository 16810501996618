@import '../../../styles/responsive.scss';

.form {
  width: 100%;
  max-width: 600px;
  margin-bottom: 50px;
}

.furnitureListHeader {
  cursor: pointer;
  display: inline-block;

  * {
    vertical-align: middle;
    display: inline-block;
  }
}

.furnitureListHeaderIcon {
  transition: transform 0.2s ease-in-out;
  margin-right: 10px;

  &.furnitureListHeaderIconCollapsed {
    transform: rotate(-90deg);
  }
}

.furnitureListHeaderCount {
  margin-left: 10px;
  padding: 4px 8px;
  border-radius: 50%;
  background-color: var(--color-secondary-200);
  color: var(--color-secondary-600);
  font-size: var(--fs-12);
  min-width: 22px;
  text-align: center;
}

.furniturePiece {
  padding: 20px;
  border: 2px solid var(--color-primary-200);
  border-radius: 10px;
  border-left-width: 10px;
  margin-top: 15px;
}

.furniturePieceHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.addRoomBtn {
  position: fixed !important;
  left: calc(min(100vw, 1920px) - 50px);
  top: calc(76px + 110px + 15px);
  z-index: 1 !important;
  transform: translateX(-100%);
  text-wrap: nowrap;
  min-width: 202px;
}

.title {
  font-size: var(--fs-24);
  font-weight: 700;
}

.room {
  margin-bottom: 60px;

  & + .room {
    border-top: 1px solid var(--color-primary-200);
    padding-top: 50px;
  }
}

.heading {
  display: flex;
  align-items: center;
  gap: 10px;
}

.equipmentLabel {
  display: flex;
  align-items: center;
  gap: 10px;
}

.camera {
  z-index: 100;
}

@include laptop {
  .addRoomBtn {
    left: calc(100vw - 30px);
  }
}

@include tablet {
  .addRoomBtn {
    left: 210px;
    top: 186px;
    width: calc(100vw - 210px - 5px);
    background-color: var(--color-white);
    transform: none;
    padding: 0 10px 10px;

    button {
      width: 100%;
    }
  }

  .form {
    margin-top: 50px;
  }
}

@include mobile {
  .addRoomBtn {
    left: 0;
    top: calc(76px + 95px);
    width: 100vw;
    padding: 0 20px 10px;
  }

  .score {
    gap: 5px;

    button {
      padding: 8px 12px;
    }
  }

  .furniturePiece {
    padding: 10px;
    border-left-width: 7px;
    border-radius: 7px;
    margin: 15px -7px 0;
  }

  .furniturePieceHeader {
    gap: 10px;
  }
}