.documentNameValue {
    font-size: var(--fs-13);
    font-weight: 700;
    cursor: pointer;
    display: initial;

    &:hover {
        text-decoration: underline;
    }
}

.pagination {
    display: flex;
    justify-content: center;
}

.propertyCell {

}

.propertyName {

}

.propertyAddress {

}

.documentTypeHeader {
    color: var(--color-primary-500);
}

.name {
    color: var(--color-secondary);
    font-weight: 700;
    text-decoration: underline;
}

.externalLink {
    margin-left: .3rem;
}

.leaseHeaderRow {
    border-top: 1px solid var(--color-primary-200) !important;
    border-bottom: none !important;
}

.leaseDocumentRow {
    border-bottom: 1px dashed var(--color-primary-100) !important;
}