@import "../../../styles/responsive.scss";

.statsContainer {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 2fr); // creates 2 columns
}

.heading {
    margin-top: 40px;
}

.header h2 {
    display: flex;
    align-items: center;
}

.statBlock {
    margin-top: 15px;
    max-width: 500px;
    margin-bottom: 30px;
    
    h3 {
        margin-bottom: 1rem;
    }
}

@include mobile {
    .statsContainer {
        grid-template-columns: repeat(1, 1fr); // creates 1 column
    }
}