@import "../../../../styles/responsive.scss";

.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 15px;
}

.arrowContainer {
  width: 0px;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  transition: all 0.2s ease;
}

.simpleNumber {
  width: 25%;
  padding: 10px 20px;

  &:hover {
    .arrowContainer {
      width: 16px;
    }
  }
}

.value {
  font-size: min(2.1vw, 40px);
  color: var(--color-secondary);
  font-weight: 700;
  text-align: center;
  line-height: 1;
  text-wrap: nowrap;
}

.text {
  font-size: var(--fs-16);
  color: var(--color-primary-500);
  font-weight: 500;
  text-align: center;
  line-height: 1;
  margin-top: 5px;
  vertical-align: middle;
  text-wrap: balance;
}

.shorterText {
  .value {
    font-size: min(1.55vw, 30px);
    margin-top: 5px;
  }

  .text {
    margin-top: 10px;
  }
}

.skeletonValue {
  margin: 0 auto -20px;
  display: block;
}

.clickable {
  cursor: pointer;
}

.progressStat {
  width: 50%;
  padding: 10px 40px;

  .value, .text {
    text-align: left;
  }
}

@media screen and (max-width: 1700px) {
  .value {
    font-size: 1.8vw;
  }
  .text {
    font-size: var(--fs-14);
  }
  .shorterText {
    .value {
      font-size: 1.36vw;
    }
  }
}

@media screen and (max-width: 1600px) {
  .simpleNumber {
    width: calc(100% / 3);
    padding: 10px;
  }
  .value {
    font-size: 1.9vw;
  }
  .shorterText {
    .value {
      font-size: 1.4vw;
    }
  }
  .progressStat {
    width: 66%;
    margin: auto;
  }
}

@include laptop {
  .text {
    font-size: var(--fs-13);
  }
}

@include laptop-xs {
  .simpleNumber {
    width: 25%;
  }
  .progressStat {
    width: 50%;
  }
  .value {
    font-size: 30px;
  }
  .shorterText {
    .value {
      font-size: 22px;
    }
  }
}

@include tablet {
  .simpleNumber {
    .arrowContainer {
      width: 16px;
    }
  }
}

@media screen and (max-width: 600px) {
  .simpleNumber {
    width: calc(100% / 3);
  }
  .progressStat {
    width: 66%;
  }
}

@media screen and (max-width: 500px) {
  .simpleNumber {
    width: 50%;
  }
  .progressStat {
    width: 100%;
  }
}