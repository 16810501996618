@import "../../../styles/responsive.scss";

.ibanChoice {
  display: flex;
  flex-direction: column;
  gap: 7px;
  border: 3px solid var(--color-primary-200);
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.2s;
  min-width: 340px;
  max-width: 100%;
  position: relative;

  &:not(.disabled):hover {
    border-color: var(--color-secondary-300);
  }

  small {
    font-size: 12px;
    color: var(--color-primary-400);
    display: block;
  }

  &.active {
    border-color: var(--color-secondary) !important;
    background-color: var(--color-secondary-100);
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.editPicto {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  cursor: pointer;
  opacity: 0.75;

  &:hover {
    opacity: 1;
  }
}

@include mobile {
  .ibanChoice {
    width: 100%;
    min-width: unset;
  }
}