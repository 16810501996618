.inputWrapper {
    position: relative;

    button {
        pointer-events: none;
    }

    label {
        cursor: pointer;
    }
}

.fileInput {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
}

.fileInputLabel {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 700;
    font-size: var(--fs-13);
    color: var(--color-secondary);
    text-decoration: underline;
}

.fileInputBtn {
    pointer-events: none;
}

.fileInputSelected {
    display: block;
    display: flex;
    align-items: center;
    gap: 5px;

    .fileInputSelectedName {
        font-weight: 700;
        font-size: var(--fs-16);
        color: var(--color-secondary);
        text-decoration: underline;
    }

    .fileInputSelectedIcon {
        cursor: pointer;
        margin-left: 5px;
    }
}

.fileInputHelp {
    font-size: var(--fs-13);
    color: var(--color-primary-500);
    margin: .4rem 0 0 0;
}
