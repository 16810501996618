@import "../../../styles/responsive.scss";

.externalLink {
  margin-left: .3rem;
}

.tableName {
    display: flex;
    align-items: center;
    gap: 5px;
}

.multipleLineColumn {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.largeInput {
    width: 380px;
}

.fullWidthInput {
    width: 100%;
}

.inputGroup {
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
}

.separator {
    border: none;
    height: 1px;
    background-color: var(--color-primary-100);
}

.ibanList {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
}

.maskedIban {
    width: 300px;
    letter-spacing: 1px;
}

.submit {
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 30px;
}

.cancel {
    font-size: 12.8px;
    color: var(--color-secondary);
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700;
    line-height: var(--fs-16);
}

.modalContent {
    width: 370px;
    margin-top: -10px;

    button[type="submit"] {
        margin: auto;
    }
}

.temporaryIdentityMsg {
    line-height: 1.5;
    margin-bottom: -30px;
}

.modalButtonContainer {
    display: flex;
    gap: 1rem;

    > * {
        flex: 1;
    }
}

.pendingRequest {
    display: flex;
    align-items: center;
    gap: 15px;

    p {
        font-size: 12.8px;
        line-height: 1.5;
    }
}

.cancelPendingRequest {
    font-size: var(--fs-12) !important;
    background-color: transparent !important;
}

.pendingRequestBtns {
    display: flex;
    gap: 15px;
}

@include mobile {
    .addIbanBtnWrapper {
        width: 100%;
    }

    .modalContent {
        width: 100%;
    }

    .pendingRequest {
        flex-direction: column;
        gap: 5px;
        align-items: flex-start;
    }
}