@import '../../../styles//responsive.scss';

.container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.option {
  padding: 8px 16px;
  background-color: var(--color-primary-100);
  cursor: pointer;
  border-radius: 8px;
  width: 25%;
  font-size: var(--fs-14);
  text-align: center;
  appearance: none;
  border: none;
  outline: none;
  font-family: var(--font-primary);

  &.active {
    &:nth-child(1) {
      background-color: var(--color-accent-green);
    }
  
    &:nth-child(2) {
      background-color: var(--color-secondary-200);
      color: var(--color-secondary-600);
    }
  
    &:nth-child(3) {
      background-color: var(--color-accent-yellow-200);
      color: var(--color-accent-yellow-600);
    }
  
    &:nth-child(4) {
      background-color: var(--color-accent-red-300);
      color: var(--color-accent-red-500);
    }
  }
}

.error {
  color: var(--color-accent-red-500);
  margin-top: 10px;
  margin-left: 20px;
  display: block;
  font-size: var(--fs-12);
}