.modalContent {
  text-align: center;
  margin: -10px;
}

.draft, .sign {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.draft {
  padding: 10px 20px 30px;
}

.sign {
  background-color: var(--color-secondary-100);
  border-radius: 10px;
  padding: 20px;
  color: var(--color-secondary);
}

.title * {
  vertical-align: middle;
}