.docsRequirements {
  padding: 20px;
  background-color: var(--color-primary-100);
  border-radius: 10px;
  margin-top: 20px;

  ul {
      padding-left: 20px;

      li::marker {
          color: var(--color-secondary);
      }
      li + li {
          margin-top: 5px;
      }
  }
}