.label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-primary-500);
  cursor: text;
  font-family: var(--font-primary);
  font-weight: 500;
  z-index: 1;
  transition: 0.2s top ease-in-out, 0.2s transform ease-in-out, 0.2s font-size ease-in-out !important;
}

.container {
  display: flex;
  flex-flow: column wrap;
  position: relative;
  width: 100%;
}

.container :global(.react-select__menu) {
  z-index: 3 !important;
  border-radius: 10px !important;
  box-shadow: none;
  border: 2px solid var(--color-primary-200);
}

.container :global .react-select__menu-list {
  padding: 0 !important;
  border-radius: 10px !important;
}

.container :global .react-select__option {
  border-radius: 0px;
  transition: all 0.1s;
  cursor: pointer;
}

.container :global .react-select__single-value,
.container :global .react-select__input-container,
.container :global .react-select__placeholder {
  position: relative;
  bottom: 0;
  margin: 0;
  height: 24px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: 0.2s bottom ease-in-out;
}

.container :global .react-select__option,
.container :global .react-select__control .react-select__single-value {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}

.container :global .react-select__option:active {
  background: var(--color-secondary);
}

.container :global .react-select__option:first-child {
  margin-top: 0;
}

.container :global .react-select__option--is-focused {
  background: var(--color-primary-100);
}

.container :global(.react-select__option--is-focused) label {
  background: var(--color-primary-100);
}

.container :global .react-select__option--is-selected {
  background: var(--color-secondary);
  font-weight: 700;
  color: var(--color-white);
  cursor: initial;
}

.container :global(.react-select__control) {
  box-sizing: border-box !important;
  width: 100% !important;
  position: relative !important;
  outline: none !important;
  padding: 0 28px !important;
  height: 50px;
  border: 2px solid var(--color-primary-200);
  border-radius: 25px;
  background-color: transparent !important;
  color: var(--color-black-700);
  transition: padding-top 0.3s ease-in-out !important;
  appearance: none;
  font-family: "Gilroy", sans-serif;
}

.error.container :global(.react-select__control) {
  border: 2px solid var(--color-accent-red-500);
}

.valid.container :global(.react-select__control) {
  border: 2px solid var(--color-secondary);
}

.container :global .react-select__control * {
  box-sizing: border-box !important;
  padding: 0 !important;
}

.container :global .react-select__control--is-focused {
  border-color: var(--color-primary-500) !important;
  box-shadow: none;
}

.container :global(.react-select__control--is-focused) label {
  color: var(--color-primary-500) !important;
}

.container :global(.react-select__value-container--has-value) label {
  color: var(--color-primary-300) !important;
}

.isSearchable {
  top: 50%;
}

.container :global(.react-select__control--is-focused) .label.isSearchable,
.label.isFocused {
  top: -20px;
  background-color: var(--color-white) !important;
  padding: 0 5px !important;
  transform: translateY(0);
  font-size: var(--fs-13);
  font-weight: 500;
  color: var(--color-primary-500);
}

.container :global(.react-select__control .react-select__single-value),
.container :global(.react-select__control--is-focused .react-select__input-container) {
  position: relative;
}

/* Change text in autofill textbox */
.container :global .react-select__control input:-webkit-autofill {
  -webkit-text-fill-color: var(--color-black) !important;
}

.container :global .react-select__clear-indicator,
.container :global .react-select__dropdown-indicator {
  bottom: -2px;
  position: relative;
  color: var(--color-primary-300);
}

.container :global .react-select__clear-indicator {
  margin-right: var(--margin-xs);
}

/* Disabled */
.container.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}

.container.disabled :global .react-select__single-value {
  color: var(--color-grey-300) !important;
}

.container.disabled .labelSelect {
  color: var(--color-grey-300) !important;
}

.context {
  margin: 0.3rem 0 0 1.3rem;
  font-size: var(--fs-12);
  line-height: var(--fs-14);
}

.topContext {
  margin: 0 0 0.7rem 1.3rem;
  font-size: var(--fs-12);
  line-height: var(--fs-14);
}

.fieldError {
  color: var(--color-accent-red-500);
}

.fieldInfo {
  color: var(--color-primary-500);
}

.cityGroup {
  display: flex;
  gap: 20px;

  .postCode {
      flex: 0.42;
  }

  .city {
      flex: 0.58;
  }
}

.editAddress {
  font-size: var(--fs-12);
  text-align: right;
  color: var(--color-primary-500);
  margin-top: 0.7rem;

  .editAddressBtn {
      cursor: pointer;
      display: inline-block;

      &:hover {
          text-decoration: underline;
      }
  }
}