@import "../../styles/responsive.scss";

.contentLeft {
    font-size: 90px;
    color: var(--color-white);
    max-width: 444px;
    text-align: center;
    position: relative;

    .circle {
        position: absolute;
        pointer-events: none;
        left: 150px;
        top: 3px;
        z-index: 1;
        width: 204px;
    }

    .line1 {
        position: absolute;
        pointer-events: none;
        left: 30px;
        bottom: -8px;
        z-index: 1;
        width: 383px;
    }

    .line2 {
        position: absolute;
        pointer-events: none;
        left: 70px;
        bottom: -24px;
        z-index: 1;
        width: 296px;
    }
}

.title {
    font-size: var(--fs-25);
    font-weight: 700;
}

.subTitle {
    font-weight: 500;
    margin: 1.3rem 0 0.75rem;
}

.userTypes {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .haveAccount {
        font-size: 12.8px;
        margin-top: 1rem;

        a {
            margin-left: .45rem;
        }
    }
}

.userSection {
    padding: 1rem 0 1.3rem 0;
    width: 100%;

    &:nth-child(2) {
        border-top: 1px solid var(--color-primary-100);
        border-bottom: 1px solid var(--color-primary-100);
    }

    .userTitle {
        font-weight: 700;
    }
}

.userTenantHeading {
    padding: 0 0 2rem;
    text-align: center;

    .userTitle {
        font-size: var(--fs-18);
    }
}

.userCtas {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

@include laptop-xs {
    .contentLeft {
        font-size: 60px;

        .circle {
            width: 130px;
            left: 175px;
            top: 5px;
        }

        .line1 {
            width: 260px;
            left: 92px;
            bottom: -6px;
        }

        .line2 {
            width: 200px;
            left: 120px;
            bottom: -16px;
        }
    }
}

@include tablet {
    .contentLeft {
        font-size: 40px;
        max-width: 300px;

        .circle {
            width: 90px;
            left: 118px;
            top: 2px;
        }

        .line1 {
            width: 173px;
            left: 65px;
            bottom: -4px;
        }

        .line2 {
            width: 135px;
            left: 80px;
            bottom: -10px;
        }
    }
}

@include mobile {
    .title {
        color: var(--color-white);
    }
    
    .subTitle {
        color: var(--color-white);
        font-weight: 500;
        margin: 1.3rem 0 2rem 0;
    }
    
}
