.orgChart {
  display: block;
}

.leaf {
  background-color: var(--color-white);
  padding: 10px;
  border: 1px solid var(--color-primary-300);
  border-radius: 5px;
  display: inline-block;
}