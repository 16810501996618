@import './responsive.scss';
@import './fonts.scss';

.modalIsOpen {
    display: block;
}

svg:focus {
    outline: none;
}

html, body, #root {
    overflow: hidden;
}

#sentry-feedback {
    --bottom: unset;
    --top: 0.75rem;
    --right: 340px;
}

// Stepper
.MuiStepIcon-root {
    color: var(--color-primary-300) !important;

    &.Mui-completed {
        color: var(--color-secondary-400) !important;
    }

    &.Mui-error {
        color: var(--color-accent-red) !important;
    }
}
.MuiStepConnector-root.Mui-completed .MuiStepConnector-line {
    border-color: var(--color-secondary-400) !important;
}

#menu-content:has(.inventory-edit) {
    padding: 0;
    overflow: hidden;
}

@include mobile {
    #sentry-feedback {
        --top: calc(76px + 0.5rem);
        --right: 0.5rem;
        --bottom: unset;
    }
}