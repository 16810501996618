.modal {
    min-height: 300px;
}

.modalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

.modalActions {
    display: flex;
    gap: 20px;
    justify-content: center;
}