@import "../../../styles//responsive.scss";

.propertyCard {
  background-color: #fff;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  min-height: 200px;
  -webkit-box-shadow: 5px 5px 12px 2px var(--color-primary-200); 
  box-shadow: 5px 5px 12px 2px var(--color-primary-200);
  cursor: pointer;
  transition: all .2s;

  &:hover, &.selected {
    -webkit-box-shadow: 5px 5px 12px 2px var(--color-primary-300); 
    box-shadow: 5px 5px 12px 2px var(--color-primary-300);
    transform: scale(1.04, 1.025);
  }

  &.leased {
    opacity: 0.5;
  }
}

.propertyCard__image {
  width: 45%;

  .propertyCard__image__img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    aspect-ratio: 7/6;
  }
}

.propertyCard__content {
  width: 55%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.propertyCard__content__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.price {
  font-size: var(--fs-20);
  font-weight: 700;
  display: block;
}

.propertyCard__content__header__price small {
  display: block;
  color: var(--color-primary-400);
  font-size: var(--fs-10);
  font-style: normal;
  font-weight: 500;
}

.propertyCard__content__body__address {
  font-size: var(--fs-13);
  display: block;
}

.propertyName {
  font-size: var(--fs-16);
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
}

.propertyCard__content__body__surface {
  font-size: var(--fs-13);
  color: var(--color-primary-600);
  line-height: 1.5;
}

.propertyCard__content__body__description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-height: 40px;
  overflow: hidden;
  color: var(--color-primary-600);
  font-size: var(--fs-10);
  line-height: 1.4;

  * {
    color: var(--color-primary-600);
    font-size: var(--fs-10);
    line-height: 1.4;
  }
}

@include tablet {
  .price {
    font-size: var(--fs-18);
  }
}

@include mobile {
  .propertyCard__image__img {
    aspect-ratio: 7/5;
  }
}