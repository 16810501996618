@import "../../../styles/responsive.scss";

.searchMap {
  width: calc(100vw - min(31vw, 700px) - 206px + 40px);
  height: calc(100vh - 76px);

  &.isPublic {
    width: calc(100vw - min(31vw, 700px) + 40px);
  }
}

.marker {
  border-radius: 20px;
  background-color: var(--color-white);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.40);
  padding: 6px 10px;
  color: var(--color-secondary);
  text-align: center;
  font-family: Gilroy;
  font-size: var(--fs-13);
  font-style: normal;
  font-weight: 700;
  transition: all .2s;
}

.markerSelected {
  background-color: var(--color-primary);
  color: var(--color-white);
}

@include laptop {
  .searchMap {
    width: 55vw;

    &.isPublic {
      width: 62vw;
    }
  }
}

@include laptop-s {
  .searchMap {
    width: 50vw;

    &.isPublic {
      width: 57vw;
    }
  }
}

@include laptop-xs {
  .searchMap {
    width: 45vw;

    &.isPublic {
      width: 52vw;
    }
  }
}

@include tablet {
  .searchMap {
    width: 52vw;
  }
}

@include mobile {
  .searchMap {
    width: 100vw;
    height: calc(45vh - 76px);

    &.isPublic {
      width: 100vw;
    }
  }
}