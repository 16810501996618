.forgotPasswordTitle {
    font-weight: 700;
    margin-bottom: 1.5rem;
}

.noAccount {
    margin-top: 1rem;
    text-align: center;
    font-size: 12.8px;

    a {
        margin-left: .45rem;
    }
}

.userAction {
    margin-top: 2rem;
}

.submit {
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 30px;
}

.cancel {
    font-size: 12.8px;
    color: var(--color-secondary);
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700;
    line-height: var(--fs-16);
}

.dateInput {
    max-width: 200px;
}

.wrapper {
    display: flex;
    justify-content: space-between;
}

.form {
    max-width: 370px;
    width: 100%;
}

.companyTypeInfo {
    font-size: 12.8px;
    color: var(--color-primary);
    line-height: var(--fs-16);
}

.fullInput {
    width: 100%;
    text-align: left;
}

.mediumInput {
    width: 240px;
}

.editLabel {
    background-color: var(--color-white);
    color: var(--color-primary-500);
    font-size: var(--fs-13);
    font-weight: 500;
}