.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 11;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalClose {
    border: 0;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
}

.modalClose:before {
    content: "\2715";
    color: var(--color-white);
    font-size: 3rem;
}

.modalContainer {
    display: flex;
    height: 90vh;
    box-sizing: border-box;
    text-align: center;
    overflow: hidden;
    box-shadow: 0 0 41px 4px rgba(48, 48, 48, 0.5);
    border-radius: 6px;

    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
}

@keyframes mmfadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes mmfadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes mmslideIn {
    from {
        transform: translateY(15%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes mmslideOut {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-10%);
    }
}

.micromodalSlide {
    display: none;
}

.micromodalSlide[aria-hidden="false"] .modalOverlay {
    animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodalSlide[aria-hidden="false"] .modalContainer {
    animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodalSlide[aria-hidden="true"] .modalOverlay {
    animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodalSlide[aria-hidden="true"] .modalContainer {
    animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodalSlide .modalContainer,
.micromodalSlide .modalOverlay {
    will-change: transform;
}

/* Mobile */
@media screen and (max-width: 768px) {
    .modalOverlay {
        background: var(--color-black);
    }

    .modalContainer {
        padding: 0.6;
        height: 100vh;
        max-height: 100vh;
        border-radius: 0;
        margin: 0;
    }
}
