.envBanner {
  width: 100%;
  text-align: center;
  padding: 2px;
  font-size: var(--fs-10);
  position: fixed;
  top: 0;
  z-index: 11;

  &.staging {
    background-color: var(--color-secondary-400);
    color: var(--color-white);
  }

  &.preprod {
    background-color: var(--color-accent-green);
    color: var(--color-black);
  }

  &.unknown {
    background-color: var(--color-tertiary);
    color: var(--color-black);
  }
}