.importActionPicto {
  cursor: pointer;
}

.importBtnWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}

.accepted {
  background-color: var(--color-accent-green-100) !important;
}

.rejected {
  background-color: var(--color-accent-red-300) !important;
}

.input {
  background-color: var(--color-white) !important;
  border-radius: 25px;
}

.number {
  text-align: right;
}

.errorText {
  color: var(--color-accent-red);
}

.pagination {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.pictoContainer {
  display: flex;
}