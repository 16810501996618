.submitBtn {
    margin: 1.5rem 0;

    button {
        margin: auto;
    }
}

.errorModalSubtitle {
    max-width: 400px;
}

.missingList {
    list-style-type: none;
}

.missingBlock {
    max-width: 500px;
}

.requiredDocumentsPhysical * {
    font-size: var(--fs-16) !important;
}