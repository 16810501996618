@import "../../../../styles/responsive.scss";

.form {
    max-width: 420px;
    width: 100%;
}

.submit {
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 30px;
}

.cancel {
    font-size: 12.8px;
    color: var(--color-secondary);
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700;
    line-height: var(--fs-16);
}

.mediumInput {
    width: 240px;
}

.container {
    display: flex;
    gap: 10%;
}

.recap {
    display: flex;
    gap: 5px;
    align-items: center;
    flex-direction: column;
    width: 300px;
    max-width: 100%;
}

.recapItem {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-direction: column;
    background-color: var(--color-primary-100);
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    text-align: center;
}

.arrowIcon {
    transform: rotate(90deg);
}

.calcul {
    display: flex;
    gap: 15px;
    align-items: center;
}

.recapBalance {
    margin-right: -10px;
    margin-top: 3px;
}

.recapTransferAmount {
    font-weight: bold;
    margin: -5px -10px 0 -8px;
}

.recapTotalAmount {
    font-weight: bold;
    font-size: var(--fs-24);
    color: var(--color-secondary);
    margin-right: -15px;

    &.recapTotalAmountNegative {
        color: var(--color-accent-red);
    }
}

.recapError {
    font-style: italic;
}

@include mobile {
    .container {
        flex-direction: column;
        gap: 20px;
    }

    .recap {
        width: 100%;
        max-width: 420px;
    }
}