.legend {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.legendItem {
  display: flex;
  align-items: center;
  gap: 15px;
  font-weight: 700;
  font-size: var(--fs-18);
}

.legendColor {
  $size: 25px;
  width: $size;
  height: $size;
  min-width: $size;
  border-radius: 50%;
}

.legendLabel {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
  width: 100%;
}

.legendLink {
  color: var(--color-primary);
  text-decoration: none;
  color: var(--color-primary-400);
  font-size: var(--fs-14);
  font-weight: 500;
  margin-right: 20px;
}