@import "../../styles/responsive.scss";

.contentLeft {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .imageBox {
        position: relative;
        width: 324px;
        height: 329px;

        .image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            mask-image: url(../../assets/icons/masks/11-1.svg);
            z-index: 1;
        }

        .gribouillon {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
        }

        .dot {
            position: absolute;
            top: 22px;
            right: -20px;
            z-index: 2;
        }
    }
}

.title {
    font-size: var(--fs-25);
    font-weight: 700;
}

.subTitle {
    font-weight: 500;
    margin: 1.3rem 0 2rem 0;
}

.youAre {
    font-weight: 700;
}

.userTypes {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .userSection {
        padding: 1rem 0 1.3rem 0;
        width: 100%;

        &:nth-child(2) {
            border-top: 1px solid var(--color-primary-100);
            border-bottom: 1px solid var(--color-primary-100);
        }

        .userTitle {
            font-weight: 700;
        }

        .userAction {
            font-size: 12.8px;
            line-height: var(--fs-20);
        }

        &.tenant {

        }

        &.owner {

        }

        &.pro {

        }
    }

    .haveAccount {
        font-size: 12.8px;

        a {
            margin-left: .45rem;
        }
    }
}

@include laptop-xs {
    .contentLeft {
        .imageBox {
            width: 250px;
            height: 253px
        }
    }
}

@include tablet {
    .contentLeft {
        display: none;
    }
}

@include mobile {
    .title {
        color: var(--color-white);
    }
    
    .subTitle {
        color: var(--color-white);
        font-weight: 500;
        margin: 1.3rem 0 2rem 0;
    }
    
}
