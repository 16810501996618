.externalLink {
  margin-left: .3rem;
}

.tableName {
    display: flex;
    align-items: center;
    gap: 5px;
}

.multipleLineColumn {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.largeInput {
    width: 380px;
}

.fullWidthInput {
    width: 100%;
}

.inputGroup {
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
}

.separator {
    border: none;
    height: 1px;
    background-color: var(--color-primary-100);
}

.copyIcon {
    cursor: pointer;
    margin-left: 5px;
}

.maskedIban {
    width: 300px;
    letter-spacing: 1px;
}

.seeMore {
    display: flex;
    align-items: center;

    svg {
        cursor: pointer
    }
}