@import "../../../styles/responsive.scss";

.login {
    background-color: var(--color-primary);
    height: 100vh;
    display: flex;
    position: relative;

    .mobileContent {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: auto;

        .content {
            flex-grow: 1;
        }
    }

    .header {
        position: absolute;
        top: 20px;
        left: 30px;
        z-index: 1;
    }

    .contentLeft {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .contentRight {
        flex: 0 0 615px;
        background-color: var(--color-white);
        border-radius: 40px 0px 0px 0px;
        padding: 5.0625rem 8.25rem 1.5rem 8.25rem;
        overflow: auto;

        @supports selector(::-webkit-scrollbar) {
            &::-webkit-scrollbar {
                width: 10px;
            }
            &::-webkit-scrollbar-track {
                background: transparent;
            }
            &::-webkit-scrollbar-thumb {
                background-color: var(--color-primary-300);
                border-radius: 45px;
                border: 3px solid var(--color-white);
        }
        }
        @supports not selector(::-webkit-scrollbar) {
            scrollbar-width: thin;
            scrollbar-color: var(--color-primary-300) transparent;
        }
    }
}

@include laptop {
    .login {
        .contentRight {
            padding: 4rem 5rem 1.5rem;
        }
    }
}

@include tablet {
    .login {
        .contentRight {
            flex: 0 0 550px;
            padding: 3rem 5rem;
        }
    }
}

@include mobile {
    .login {
        flex-direction: column;

        .header {
            position: initial;
            margin: 2rem auto 1.3rem auto;
            padding: 0 1.25rem;
            align-items: center;
            text-align: center;
        }

        .contentHeading {
            padding: 0 1.25rem;
        }

        .content {
            background-color: var(--color-white);
            border-radius: 40px 0px 0px 0px;
            padding: 1.4rem 1.25rem 100px;
        }
    }
}
