@import "../../styles/responsive.scss";

.welcomeTitle {
    max-width: 371px;
}

.heading {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    flex-wrap: wrap;
}

.mask {
    position: absolute;
    bottom: 40px;
    right: 40px;
    width: 272px;
    height: 548px;
    object-fit: cover;
    mask-image: url(../../assets/icons/masks/lease-1.svg);
    z-index: 1;
}

.welcome {
    max-width: 370px;
}

.modal {
    min-height: 500px;
    max-width: 480px;
}

.imageModal {
    min-height: 500px;
}

.newMessageForm {
    text-align: left;
}

.separator {
    border-top-color: transparent;
    border-bottom-color: var(--color-primary-100);
    border-left-color: transparent;
    border-right-color: transparent;
}

.submitFileBlock {
    display: flex;
    justify-content: flex-end;
}

.submitBlock {
    margin: 0 auto;
}

.fileBlock {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 1rem;
}

.content {
    height: calc(100% - 90px);
}

@include tablet {
    .mask {
        display: none;
    }
}

@include mobile {
    .welcome {
        max-width: 100%;
    }
}