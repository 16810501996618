@import '../../../styles/responsive.scss';

.filters {
  flex: 1;
}

.filterGroup {
  margin: 2rem 0;
  h3 {
      margin-bottom: .9rem;
  }
}

.stickyBottom {
  position: sticky;
  bottom: 0;
  background-color: white;
  box-shadow: -10px -20px 20px white;
}

.filterSeparator {
  margin: 0 -23px;
  background-color: var(--color-primary-100);
  height: 1px;
  border: none;
}

.filterActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  background-color: white;
}

.reinit {
  font-size: .8rem;
  font-weight: 700;
  color: var(--color-secondary);
  text-decoration: underline;
  cursor: pointer;
}

.filterInputGroup {
  display: flex;
  flex-direction: row;
  gap: 2.5%;
  align-items: center;
}

.filterInput {
  width: 47.5%;
  display: inline-block;
}

@include mobile {
  .filterActions {
    padding-bottom: 100px;
  }
}