.profile3 {
  width: 1600px;
  max-width: 100%;
}

.missingList {
  margin-left: 20px;
  line-height: 1.5;
}

.missingBlock {
  display: flex;
  justify-content: center;
  max-width: 500px;

  & > div {
      width: 100%;
  }
}